import API from "@/libs/API/index";

const state = () => {
  return {
    initialized: false,
    taxes: []
  };
};

// getters
const getters = {
  taxes: state => state.taxes
};

// actions
const actions = {
  async initialize({ commit }) {
    commit("SET_INITIALIZED");
    await actions.refresh({ commit });
  },
  async refresh({ commit }) {
    const response = await API.taxes.getAll();
    commit("SET_TAXES", { taxes: response.data });
  }
};

// mutations
const mutations = {
  ["SET_INITIALIZED"](state) {
    state.initialized = true;
  },
  ["SET_TAXES"](state, { taxes }) {
    state.taxes = taxes;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
