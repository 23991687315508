import base from "@/libs/API/partials/base";

const presale = {
  async getAll() {
    const url = base.getAdminUrl("presale");
    return await base.get(url);
  },
  async get(id) {
    const url = base.getAdminUrl("presale/" + id);
    return await base.get(url);
  },
  async submit(data) {
    const url = base.getAdminUrl("presale");
    return await base.post(url, data);
  }
};

export default presale;
