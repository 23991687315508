<template>
  <div>
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">Order bekijken</h2>
      <button
        class="btn btn-primary"
        type="button"
        @click="downloadFirstInvoice"
      >
        Factuur downloaden
      </button>
      <button
        class="btn btn-primary"
        type="button"
        @click="downloadPackageSlip"
      >
        Pakbon downloaden
      </button>
    </div>
    <AlertBar :key="alertBarKey" />
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div v-if="order.orderLines.length > 0" class="intro-y col-span-12">
        <!-- BEGIN: Form Layout -->
        <div class="intro-y box p-5">
          <h2 class="text-lg font-medium mr-auto pb-2">Details</h2>
          <div class="grid grid-cols-3 gap-6 my-2">
            <div class="box p-5 my-2 col-span-1">
              <h2 class="text-lg font-medium mr-auto pb-2">Gebruiker:</h2>
              <router-link
                v-if="order.user.id"
                :to="{ name: 'customer.edit', params: { id: order.user.id } }"
              >
                {{ order.user.email }}
              </router-link>
              <p v-else>Geen gebruiker gekoppeld</p>
            </div>
            <div class="box p-5 my-2 col-span-1">
              <h2 class="text-lg font-medium mr-auto pb-2">Verzendmethode:</h2>
              <p>{{ order.shippingMethod }}</p>
            </div>
            <div class="box p-5 my-2 col-span-1">
              <h2 class="text-lg font-medium mr-auto pb-2">Betaalmethode:</h2>
              <p>{{ order.paymentMethod }}</p>
            </div>
          </div>
          <div class="grid grid-cols-2 gap-6 my-2">
            <div class="box col-span-1 p-5">
              <h2 class="text-lg font-medium mr-auto">Verzendadres</h2>
              <table>
                <tr>
                  <td>Voornaam:</td>
                  <td>{{ order.shippingAddress.first_name }}</td>
                </tr>
                <tr>
                  <td>Tussenvoegsel:</td>
                  <td>{{ order.shippingAddress.middle_name }}</td>
                </tr>
                <tr>
                  <td>Achternaam:</td>
                  <td>{{ order.shippingAddress.last_name }}</td>
                </tr>
                <tr>
                  <td>Bedrijf:</td>
                  <td>{{ order.shippingAddress.company }}</td>
                </tr>
                <tr>
                  <td>Adres regel 1:</td>
                  <td>{{ order.shippingAddress.address_line_1 }}</td>
                </tr>
                <tr>
                  <td>Adres regel 2:</td>
                  <td>{{ order.shippingAddress.address_line_2 }}</td>
                </tr>
                <tr>
                  <td>Stad:</td>
                  <td>{{ order.shippingAddress.city }}</td>
                </tr>
                <tr>
                  <td>Postcode:</td>
                  <td>{{ order.shippingAddress.postal_code }}</td>
                </tr>
                <tr>
                  <td>Land:</td>
                  <td>{{ order.shippingAddress.country_id }}</td>
                </tr>
                <tr>
                  <td>Telefoonnummer:</td>
                  <td>{{ order.shippingAddress.phone }}</td>
                </tr>
              </table>
            </div>
            <div class="box col-span-1 p-5">
              <h2 class="text-lg font-medium mr-auto">Factuuradres</h2>
              <table>
                <tr>
                  <td>Voornaam:</td>
                  <td>{{ order.paymentAddress.first_name }}</td>
                </tr>
                <tr>
                  <td>Tussenvoegsel:</td>
                  <td>{{ order.paymentAddress.middle_name }}</td>
                </tr>
                <tr>
                  <td>Achternaam:</td>
                  <td>{{ order.paymentAddress.last_name }}</td>
                </tr>
                <tr>
                  <td>Bedrijf:</td>
                  <td>{{ order.paymentAddress.company }}</td>
                </tr>
                <tr>
                  <td>Adres regel 1:</td>
                  <td>{{ order.paymentAddress.address_line_1 }}</td>
                </tr>
                <tr>
                  <td>Adres regel 2:</td>
                  <td>{{ order.paymentAddress.address_line_2 }}</td>
                </tr>
                <tr>
                  <td>Stad:</td>
                  <td>{{ order.paymentAddress.city }}</td>
                </tr>
                <tr>
                  <td>Postcode:</td>
                  <td>{{ order.paymentAddress.postal_code }}</td>
                </tr>
                <tr>
                  <td>Land:</td>
                  <td>{{ order.paymentAddress.country_id }}</td>
                </tr>
                <tr>
                  <td>Telefoonnummer:</td>
                  <td>{{ order.paymentAddress.phone }}</td>
                </tr>
              </table>
            </div>
          </div>
          <div class="box p-5 my-2">
            <h2 class="text-lg font-medium mr-auto pb-2">Bestelregels</h2>
            <table class="table table--sm">
              <thead>
                <tr>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Productafbeelding
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Productnaam
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    SKU
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Prijs
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Aantal
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Totaal
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="orderLine of order.orderLines" :key="orderLine.id">
                  <td class="border-b dark:border-dark-5 w-40">
                    <img
                      v-if="orderLine.image"
                      :src="orderLine.image"
                      :alt="orderLine.productName"
                    />
                  </td>
                  <td class="border-b dark:border-dark-5">
                    <router-link
                      v-if="orderLine.productName"
                      :to="{
                        name: 'product.edit',
                        params: { slug: orderLine.productParentSlug }
                      }"
                    >
                      {{ orderLine.productName }}
                    </router-link>
                  </td>
                  <td class="border-b dark:border-dark-5">
                    {{ orderLine.sku }}
                  </td>
                  <td class="border-b dark:border-dark-5">
                    {{ orderLine.price }}
                  </td>
                  <td class="border-b dark:border-dark-5">
                    {{ orderLine.quantity }}
                  </td>
                  <td class="border-b dark:border-dark-5">
                    {{ orderLine.total }}
                  </td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <td colspan="4"></td>
                  <td>Subtotaal</td>
                  <td>{{ order.subtotal }}</td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td>Verzendkosten</td>
                  <td>{{ order.shippingCosts }}</td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <td>BTW</td>
                  <td>{{ order.vat }}</td>
                </tr>
                <tr>
                  <td colspan="4"></td>
                  <th>Totaal</th>
                  <th>{{ order.total }}</th>
                </tr>
              </tfoot>
            </table>
          </div>
          <div class="grid grid-cols-2 gap-6 my-2">
            <div class="box col-span-1 p-5">
              <h2 class="text-lg font-medium mr-auto">Pakbonnen</h2>
              <p>
                Nog niet beschikaar. Knop te vinden rechts bovenin het scherm.
              </p>
            </div>
            <div class="box col-span-1 p-5">
              <h2 class="text-lg font-medium mr-auto">Facturen</h2>
              <table class="table table--sm">
                <thead>
                  <tr>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Factuurnummer
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Datum
                    </th>
                    <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                      Download
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="invoice of order.invoices" :key="invoice.number">
                    <td>{{ invoice.number }}</td>
                    <td>{{ invoice.date }}</td>
                    <td>
                      <button
                        type="button"
                        class="btn btn-primary"
                        @click="downloadInvoice(invoice.id)"
                      >
                        Download
                      </button>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="box p-5 my-2">
            <h2 class="text-lg font-medium mr-auto pb-2">Order historie</h2>
            <table class="table table--sm">
              <thead>
                <tr>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Datum
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Order status
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Opmerking
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Mail verzonden
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="create_history">
                  <td class="border-b dark:border-dark-5">
                    <button
                      class="btn btn-primary"
                      type="button"
                      @click="addNewOrderHistory"
                    >
                      Opslaan
                    </button>
                  </td>
                  <td class="border-b dark:border-dark-5">
                    <select
                      v-model="newHistory.order_status_id"
                      class="form-select box mt-3 sm:mt-0"
                    >
                      <option selected hidden disabled :value="null">
                        --- Kies een orderstatus ---
                      </option>
                      <option :value="-1">
                        Geen
                      </option>
                      <option
                        v-for="orderStatus of orderStatuses"
                        :key="orderStatus.id"
                        :value="orderStatus.id"
                      >
                        {{ orderStatus.name }}
                      </option>
                    </select>
                  </td>
                  <td class="border-b dark:border-dark-5">
                    <textarea
                      v-model="newHistory.comment"
                      class="form-control dark:bg-dark-3 h-16 border-b px-5 py-3 shadow-none focus:ring-0"
                      rows="1"
                      placeholder="Opmerking toevoegen..."
                    ></textarea>
                  </td>
                  <td class="border-b dark:border-dark-5">
                    <div class="mt-3 text-center">
                      <label>Mail verzenden</label>
                      <div class="mt-2">
                        <input
                          v-model="newHistory.mail_sent"
                          type="checkbox"
                          class="form-check-switch"
                        />
                      </div>
                    </div>
                  </td>
                </tr>
                <tr
                  v-for="orderHistory of order.orderHistory"
                  :key="orderHistory.id"
                >
                  <td class="border-b dark:border-dark-5">
                    {{ orderHistory.date }}
                  </td>
                  <td class="border-b dark:border-dark-5">
                    {{ orderHistory.orderStatus }}
                  </td>
                  <td class="border-b dark:border-dark-5">
                    {{ orderHistory.comment }}
                  </td>
                  <td class="border-b dark:border-dark-5">
                    {{ orderHistory.mail_sent }}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import API from "@/libs/API/index";
import moment from "moment";
import localization from "../../libs/Localization";
import store from "@/store";
import AlertBar from "@/components/Alerts/AlertBar";

export default {
  name: "OrderForm",
  components: { AlertBar },
  data() {
    return {
      alertBarKey: 0,
      order: {
        user: {},
        shippingAddress: {},
        paymentAddress: {},
        shippingMethod: undefined,
        paymentMethod: undefined,
        orderLines: [],
        orderHistory: [],
        invoices: [],
        vat: "-",
        subtotal: "-",
        total: "-"
      },
      orderStatuses: [],
      create_history: true,
      newHistory: {
        order_status_id: null,
        comment: undefined,
        mail_sent: false
      }
    };
  },
  created() {
    this.refreshOrder();
    this.refreshOrderStatuses();
  },
  methods: {
    async addNewOrderHistory() {
      const response = await API.order.addHistory(
        this.$route.params.id,
        this.newHistory
      );

      await this.refreshOrder();

      if (response.status >= 200 && response.status < 300) {
        this.newHistory = {
          order_status_id: null,
          comment: undefined,
          mail_sent: false
        };

        await store.dispatch(
          "messages/addSuccessMessage",
          "Order status bijgewerkt."
        );
      } else {
        await store.dispatch(
          "messages/addErrorMessage",
          "Er ging iets mis. Probeer het opnieuw."
        );
      }

      this.alertBarKey++;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async refreshOrderStatuses() {
      const response = await API.order.getAllStatuses();
      const result = [];
      for (const orderStatus of response.data.order_statuses) {
        result.push({
          id: orderStatus.id,
          name: orderStatus.name
        });
      }

      this.orderStatuses = result;
    },
    async refreshOrder() {
      const response = await API.order.get(this.$route.params.id);
      const order = response.data.order;

      if (order.user !== null) {
        this.order.user = {
          id: order.user.id,
          email: order.user.email
        };
      }

      this.order.shippingAddress = this.processAddress(order.shipping_address);
      this.order.paymentAddress = this.processAddress(order.payment_address);
      this.order.orderLines = this.processOrderLines(order.order_lines);
      this.order.orderHistory = this.processOrderHistory(order.order_histories);
      this.order.invoices = this.processInvoices(order.invoices);
      this.order.paymentMethod = order.payment_method.description;
      this.order.shippingMethod = order.shipping_method.name;
      this.order.shippingCosts = localization.prices.format(
        order.shipping_method.cost
      );
      this.order.subtotal = localization.prices.format(order.subtotal);
      this.order.vat = localization.prices.format(order.vat);
      this.order.total = localization.prices.format(order.total);
    },
    processInvoices(invoices) {
      const result = [];

      for (const invoice of invoices) {
        result.push({
          id: invoice.id,
          number: invoice.invoice_number,
          date: localization.datetime.parse(invoice.created_at)
        });
      }
      return result;
    },
    processOrderLines(orderLines) {
      const result = [];

      for (const orderLine of orderLines) {
        result.push({
          id: orderLine.id,
          image: orderLine.product.image
            ? orderLine.product.image.url
            : undefined,
          productName: orderLine.product.name
            ? orderLine.product.name
            : undefined,
          productParentSlug: orderLine.product.parent_slug,
          quantity: orderLine.quantity,
          sku: orderLine.sku,
          price: localization.prices.format(orderLine.unitPrice),
          total: localization.prices.format(
            orderLine.unitPrice * orderLine.quantity
          )
        });
      }

      return result;
    },
    processOrderHistory(orderHistories) {
      const result = [];
      for (const orderHistory of orderHistories) {
        result.push({
          id: orderHistory.id,
          date: moment(orderHistory.created_at).format("DD-MM-YYYY HH:mm"),
          orderStatus: orderHistory.order_status.name,
          comment: orderHistory.comment,
          mail_sent: orderHistory.mail_sent ? "Ja" : "Nee"
        });
      }
      return result;
    },
    processAddress(address) {
      return {
        id: address.id,
        address_line_1: address.address_line_1,
        address_line_2: address.address_line_2,
        city: address.city,
        company: address.company,
        country_id: address.country_id,
        first_name: address.first_name,
        last_name: address.last_name,
        middle_name: address.middle_name,
        phone: address.phone,
        postal_code: address.postal_code
      };
    },
    downloadPackageSlip() {
      API.order.downloadPackageSlip(this.$route.params.id);
    },
    downloadFirstInvoice() {
      API.order.downloadFirstInvoice(this.$route.params.id);
    },
    downloadInvoice(invoiceId) {
      API.order.downloadInvoice(this.$route.params.id, invoiceId);
    }
  }
};
</script>
