import base from "@/libs/API/partials/base";

const discount = {
  async save(userId, discount) {
    const url = base.getAdminUrl("customer/" + userId + "/userDiscount");
    return await base.post(url, discount);
  },
  async delete(userId, id) {
    const url = base.getAdminUrl("customer/" + userId + "/userDiscount/" + id);
    return await base.delete(url);
  }
};

export default discount;
