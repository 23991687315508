<template>
  <div>
    <Loader v-if="loading" />
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Presale producten aanmelden
      </h2>
    </div>
    <AlertBar :key="alertBarKey" />
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12">
        <div class="intro-y box p-5">
          <!-- BEGIN: Form Layout -->
          <div
            v-for="(product, key) of products"
            :key="key"
            class="grid grid-cols-12 mt-3 gap-6"
          >
            <div class="col-span-6">
              <input
                :id="'product-sku-' + key"
                v-model="product.sku"
                type="text"
                class="form-control w-full"
                placeholder="SKU"
              />
            </div>
            <div class="col-span-6">
              <input
                :id="'product-quantity-' + product.id"
                v-model="product.quantity"
                type="number"
                class="form-control w-full"
                placeholder="Aantal"
              />
            </div>
          </div>
          <div class="grid grid-cols-12 mt-3">
            <div class="col-span-12">
              <button
                type="button"
                class="btn btn-primary"
                @click="addEmptyRow"
              >
                Regel toevoegen
              </button>
            </div>
          </div>
          <div class="text-right mt-5">
            <button
              type="button"
              class="btn btn-primary w-24"
              @click="submitPresale"
            >
              Save
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import AlertBar from "@/components/Alerts/AlertBar";
import API from "@/libs/API";
import store from "@/store";

export default {
  name: "Presale",
  components: { AlertBar, Loader },
  data() {
    return {
      loading: true,
      alertBarKey: 0,
      products: []
    };
  },
  mounted() {
    this.loading = false;
    this.addEmptyRow();
  },
  methods: {
    addEmptyRow() {
      this.products.unshift({
        sku: undefined,
        quantity: undefined
      });
    },
    async submitPresale() {
      this.loading = true;
      const data = {
        presale_products: this.products
      };

      const result = await API.presale.submit(data);

      await store.dispatch("models/presale/refresh");

      if (result.status >= 200 && result.status < 300) {
        await store.dispatch(
          "messages/addSuccessMessage",
          "Presale producten aangemeld. Ze worden nu verwerkt."
        );
      } else {
        await store.dispatch(
          "messages/addErrorMessage",
          "Er ging iets mis. Probeer het opnieuw."
        );
      }

      this.alertBarKey++;
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
