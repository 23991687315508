<template>
  <div
    class="alert alert-dismissible show flex items-center mb-2"
    :class="'alert-' + type"
    role="alert"
  >
    <component :is="icon" class="w-6 h-6 mr-2" />
    {{ message }}
    <button
      type="button"
      class="btn-close"
      data-bs-dismiss="alert"
      aria-label="Close"
    >
      <XIcon class="w-4 h-4" />
    </button>
  </div>
</template>

<script>
export default {
  name: "Alert",
  props: {
    type: {
      type: String,
      default: () => "info"
    },
    message: {
      type: String,
      required: true
    }
  },
  computed: {
    icon() {
      switch (this.type) {
        case "primary":
        case "success":
          return "AlertCircleIcon";
        case "danger":
        case "secondary":
          return "AlertOctagonIcon";
        case "dark":
        case "warning":
        default:
          return "AlertTriangleIcon";
      }
    }
  }
};
</script>
