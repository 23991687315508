import base from "@/libs/API/partials/base";

const userGroup = {
  async getAll() {
    const url = base.getAdminUrl("user-group");
    return await base.get(url);
  },
  async get(id) {
    const url = base.getAdminUrl("user-group/" + id);
    return await base.get(url);
  },
  async getPermissions() {
    const url = base.getAdminUrl("user-group/permissions");
    return await base.get(url);
  },
  async setPermissions(id, permissions) {
    const url = base.getAdminUrl("user-group/" + id);
    return await base.post(url, { permissions: permissions });
  }
};

export default userGroup;
