import API from "@/libs/API/index";
import localization from "@/libs/Localization";

const state = () => {
  return {
    initialized: false,
    presale: []
  };
};

// getters
const getters = {
  presale: state => state.presale
};

function parsePresale(presaleItems) {
  const result = [];

  for (const presaleItem of presaleItems) {
    result.push({
      id: presaleItem.id,
      date: localization.datetime.parse(presaleItem.created_at)
    });
  }

  return result;
}

// actions
const actions = {
  async initialize({ commit }) {
    commit("SET_INITIALIZED");
    await actions.refresh({ commit });
  },
  async refresh({ commit }) {
    const response = await API.presale.getAll();
    commit("SET_PRESALE", {
      presale: parsePresale(response.data.presale_jobs)
    });
  }
};

// mutations
const mutations = {
  ["SET_INITIALIZED"](state) {
    state.initialized = true;
  },
  ["SET_PRESALE"](state, { presale }) {
    state.presale = presale;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
