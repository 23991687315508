import Cookies from "@/libs/Cookies";

const COOKIE_NAME = "settingsCookie";

const addCookie = values => {
  Cookies.set(COOKIE_NAME, JSON.stringify(values));
};

const getCookie = () => {
  return JSON.parse(Cookies.get(COOKIE_NAME, "{}"));
};

const getFromCookie = (name, notFound = "") => {
  if (!Cookies.has(COOKIE_NAME)) {
    addCookie({});
  }

  const settingsCookie = getCookie();
  return settingsCookie[name] !== undefined ? settingsCookie[name] : notFound;
};

const addToCookie = (name, value) => {
  const cookie = getCookie();
  cookie[name] = value;
  addCookie(cookie);
};

const state = () => {
  return {
    token: Cookies.get("token"),
    order: {
      page: getFromCookie("orderPage", 1),
      perPage: getFromCookie("orderPerPage", 10)
    },
    product: {
      page: getFromCookie("productPage", 1),
      perPage: getFromCookie("productPerPage", 10)
    },
    customer: {
      page: getFromCookie("customerPage", 1),
      perPage: getFromCookie("customerPerPage", 10)
    }
  };
};

// getters
const getters = {
  token: state => state.token,
  "order/page": state => state.order.page,
  "order/perPage": state => state.order.perPage,
  "product/page": state => state.product.page,
  "product/perPage": state => state.product.perPage,
  "customer/page": state => state.customer.page,
  "customer/perPage": state => state.customer.perPage
};

// actions
const actions = {
  setToken({ commit }, token) {
    commit("SET_TOKEN", { token: token });
    Cookies.set("token", token);
  },
  setOrderPage({ commit }, orderPage) {
    commit("SET_ORDER_PAGE", { orderPage: orderPage });
    addToCookie("orderPage", orderPage);
  },
  setOrderPerPage({ commit }, orderPerPage) {
    commit("SET_ORDER_PER_PAGE", { orderPerPage: orderPerPage });
    addToCookie("orderPerPage", orderPerPage);
  },
  setCustomerPage({ commit }, customerPage) {
    commit("SET_CUSTOMER_PAGE", { customerPage: customerPage });
    addToCookie("customerPage", customerPage);
  },
  setCustomerPerPage({ commit }, customerPerPage) {
    commit("SET_CUSTOMER_PER_PAGE", { customerPerPage: customerPerPage });
    addToCookie("customerPerPage", customerPerPage);
  },
  deleteAuthToken({ commit }) {
    actions.setToken({ commit }, null);
  }
};

// mutations
const mutations = {
  ["SET_TOKEN"](state, { token }) {
    state.token = token;
  },
  ["SET_ORDER_PAGE"](state, { orderPage }) {
    state.order.page = orderPage;
  },
  ["SET_ORDER_PER_PAGE"](state, { orderPerPage }) {
    state.order.perPage = orderPerPage;
  },
  ["SET_CUSTOMER_PAGE"](state, { customerPage }) {
    state.customer.page = customerPage;
  },
  ["SET_CUSTOMER_PER_PAGE"](state, { customerPerPage }) {
    state.customer.perPage = customerPerPage;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
