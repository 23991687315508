import { createStore } from "vuex";
import main from "./main";
import sideMenu from "./side-menu";
import simpleMenu from "./simple-menu";
import topMenu from "./top-menu";
import dieAdmin from "./die-admin";
import messages from "@/store/messages";
import models from "@/store/Models/models";

const store = createStore({
  modules: {
    main,
    sideMenu,
    simpleMenu,
    topMenu,
    dieAdmin,
    messages,
    models
  }
});

export function useStore() {
  return store;
}

export default store;
