<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Klanten</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <router-link
          :to="{ name: 'customer.create' }"
          class="btn btn-primary shadow-md mr-2"
        >
          Klant toevoegen
        </router-link>
        <div class="hidden md:block mx-auto text-gray-600">
          {{ paginate.from }} - {{ paginate.to }} van
          {{ paginate.amountOfItems }} klanten
        </div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <form
            class="w-56 relative text-gray-700 dark:text-gray-300"
            @submit.prevent="refreshCustomers"
          >
            <input
              v-model="search"
              type="text"
              class="form-control w-56 box pr-10 placeholder-theme-8"
              placeholder="Search..."
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
              @click="refreshCustomers"
            />
          </form>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <AlertBar />
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Klant ID</th>
              <th class="whitespace-nowrap">Naam</th>
              <th class="whitespace-nowrap">Email</th>
              <th class="whitespace-nowrap">Groep</th>
              <th class="text-center whitespace-nowrap">Acties</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="customer of customers"
              :key="customer.id"
              class="intro-x"
            >
              <td class="w-40">{{ customer.id }}</td>
              <td class="w-40">
                {{ customer.name }}
              </td>
              <td>{{ customer.email }}</td>
              <td class="w-40">{{ customer.group }}</td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <button
                    class="flex items-center mr-3"
                    @click="onLoginAs(customer.id)"
                  >
                    <LogInIcon class="w-4 h-4 mr-1" /> Login
                  </button>
                  <router-link
                    :to="{
                      name: 'customer.edit',
                      params: { id: customer.id }
                    }"
                    class="flex items-center mr-3"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Edit
                  </router-link>
                  <a
                    class="flex items-center text-theme-24 mr-3"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Delete
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <Pagination
        v-if="!loading"
        :page="paginate.page"
        :max-pages="paginate.amountOfPages"
        @goToPage="onGoToPage"
      />
    </div>
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-danger w-24">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/Pagination";
import store from "@/store";
import API from "@/libs/API/index";
import AlertBar from "@/components/Alerts/AlertBar";

export default {
  name: "CustomertList",
  components: { AlertBar, Pagination },
  data() {
    return {
      loading: false,
      customers: [],
      paginate: {
        page: store.getters["dieAdmin/customer/page"],
        perPage: store.getters["dieAdmin/customer/perPage"],
        from: undefined,
        to: undefined,
        amountOfItems: 0,
        amountOfPages: 0
      },
      search: undefined
    };
  },
  watch: {
    "paginate.page"(newVal) {
      this.refreshCustomers();
      store.dispatch("dieAdmin/setCustomerPage", newVal);
    }
  },
  created() {
    this.refreshCustomers();
  },
  methods: {
    onGoToPage(page) {
      this.paginate.page = page;
    },
    async onLoginAs(userId) {
      const response = await API.customer.getAuthTokenLink(userId);

      if (response < 200 || response >= 300) {
        await store.dispatch(
          "messages/addErrorMessage",
          "Er ging iets mis. Probeer het opnieuw."
        );
        return;
      }

      const url = new URL(response.data.link);

      url.searchParams.set("user_id", userId);

      const frontendUrl =
        process.env.VUE_APP_FRONTEND_LOCATION +
        "/login-as?" +
        url.searchParams.toString();

      window.open(frontendUrl, "_blank").focus();
    },
    parseCustomerData(data) {
      const customers = [];

      for (const customer of data) {
        customers.push({
          id: customer.id,
          name: customer.name,
          email: customer.email,
          group: customer.roles.length > 0 ? customer.roles[0].name : ""
        });
      }

      return customers;
    },
    async refreshCustomers() {
      this.loading = true;
      const response = await API.customer.getAll(
        this.paginate.page,
        this.paginate.perPage,
        this.search
      );
      const data = response.data;
      this.customers = this.parseCustomerData(data.data);
      this.paginate.amountOfItems = data.total;
      this.paginate.amountOfPages = data.last_page;
      this.paginate.from = data.from;
      this.paginate.to = data.to;
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
