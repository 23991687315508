<template>
  <div>
    <Loader v-if="loading" />
    <div class="intro-y flex items-center mt-8">
      <h2 v-if="create" class="text-lg font-medium mr-auto">
        Product toevoegen
      </h2>
      <h2 v-else class="text-lg font-medium mr-auto">Product aanpassen</h2>
    </div>
    <AlertBar :key="alertBarKey" />
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <div v-if="product" class="intro-y box p-5">
          <div>
            <label class="form-label">Afbeelding</label>
            <ImagePopup
              v-if="loading === false"
              name="test"
              :upload-endpoint="'product/' + product.id + '/image'"
              :delete-endpoint="'product/' + product.id + '/image'"
              :current-images="product.images"
              @click="checkCanAddImage"
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-1" class="form-label">Hoofdafbeelding</label>
            <ImageSelector
              v-if="loading === false"
              :value="product.image_id"
              :images="product.images"
              @imagesUpdated="refreshProduct"
            />
          </div>
          <!-- BEGIN: Basic Select -->
          <div class="mt-3">
            <label>Categorieën</label>
            <div class="mt-2">
              <TomSelect
                v-model="product.categories"
                :options="{
                  placeholder: 'Categorie zoeken'
                }"
                class="w-full"
                multiple
                remove-button
              >
                <option
                  v-for="category of categories"
                  :key="category.id"
                  :value="category.id"
                >
                  {{ category.fullName }}
                </option>
              </TomSelect>
            </div>
          </div>
          <!-- END: Basic Select -->
          <div class="mt-3">
            <label for="crud-form-1" class="form-label">Productnaam</label>
            <input
              id="crud-form-1"
              v-model="product.name"
              type="text"
              class="form-control w-full"
              placeholder="Productnaam"
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-3" class="form-label">Voorraad</label>
            <div class="input-group">
              <input
                id="crud-form-3"
                v-model="product.stock_level"
                type="number"
                class="form-control"
                placeholder="Voorraad"
              />
            </div>
          </div>
          <div class="mt-3">
            <label for="crud-form-4" class="form-label">Slug</label>
            <div class="input-group">
              <input
                id="crud-form-4"
                v-model="product.slug"
                type="text"
                class="form-control"
                placeholder="Slug"
              />
            </div>
          </div>
          <div class="mt-3">
            <label>Active Status</label>
            <div class="mt-2">
              <input
                v-model="product.active"
                type="checkbox"
                class="form-check-switch"
              />
            </div>
          </div>
          <div class="mt-3">
            <label>Description</label>
            <div class="mt-2">
              <CKEditor
                v-model="product.description"
                :editor="classicEditor"
                :config="simpleEditorConfig"
              />
            </div>
          </div>
          <div class="text-right mt-5">
            <button type="button" class="btn btn-outline-secondary w-24 mr-1">
              Cancel
            </button>
            <button
              type="button"
              class="btn btn-primary w-24"
              @click="saveProduct"
            >
              Save
            </button>
          </div>
        </div>
        <!-- END: Form Layout -->
      </div>
      <div v-if="product" class="col-span-12 lg:col-span-6">
        <div class="intro-y box">
          <div
            class="flex flex-col sm:flex-row items-center p-5 border-b border-gray-200 dark:border-dark-5"
          >
            <h2 class="font-medium text-base mr-auto">Product Variaties</h2>
            <div
              class="w-full sm:w-auto flex items-center sm:ml-auto mt-3 sm:mt-0"
            >
              <button
                class="btn btn-primary shadow-md mr-2"
                @click="addVariation"
              >
                Variatie toevoegen
              </button>
            </div>
          </div>
          <div id="basic-accordion" class="p-5">
            <div class="preview">
              <div id="product-variation-accordion" class="accordion">
                <div
                  v-for="(variation, key) of product.variations"
                  :key="variation.id"
                  class="accordion-item"
                >
                  <div :id="variation.id" class="accordion-header">
                    <button
                      class="accordion-button"
                      type="button"
                      data-bs-toggle="collapse"
                      :data-bs-target="'variation-' + variation.id"
                      aria-expanded="true"
                      :aria-controls="'variation-' + variation.id"
                    >
                      {{ variation.name ? variation.name : "Nieuwe variatie" }}
                    </button>
                  </div>
                  <div
                    :id="'variation-' + variation.id"
                    class="accordion-collapse collapse show"
                    aria-labelledby="faq-accordion-content-1"
                    data-bs-parent="#faq-accordion-1"
                  >
                    <div class="intro-y box p-5">
                      <div>
                        <label class="form-label">Afbeelding</label>
                        <ImagePopup
                          name="test"
                          :upload-endpoint="
                            'product/' +
                              product.id +
                              '/variation/' +
                              variation.id +
                              '/image'
                          "
                          :delete-endpoint="
                            'product/' +
                              product.id +
                              '/variation/' +
                              variation.id +
                              '/image'
                          "
                          :current-images="variation.images"
                          @imagesUpdated="refreshProduct"
                        />
                      </div>
                      <div class="mt-3">
                        <label
                          :for="'variation-' + variation.id + '-main_image'"
                          class="form-label"
                        >
                          Hoofdafbeelding
                        </label>
                        <ImageSelector
                          v-if="!loading"
                          :value="variation.image_id"
                          :images="variation.images"
                        />
                      </div>
                      <div class="mt-3">
                        <label
                          :for="'variation-' + variation.id + '-name'"
                          class="form-label"
                          >Productnaam</label
                        >
                        <input
                          :id="'variation-' + variation.id + '-name'"
                          v-model="variation.name"
                          type="text"
                          class="form-control w-full"
                          placeholder="Productnaam"
                        />
                      </div>
                      <div class="mt-3">
                        <label
                          :for="'variation-' + variation.id + '-sku'"
                          class="form-label"
                        >
                          SKU
                        </label>
                        <div class="input-group">
                          <input
                            :id="'variation-' + variation.id + '-sku'"
                            v-model="variation.sku"
                            type="text"
                            class="form-control"
                            placeholder="SKU"
                          />
                        </div>
                      </div>
                      <div class="mt-3">
                        <label
                          :for="'variation-' + variation.id + '-sku'"
                          class="form-label"
                        >
                          Artikelnummer (ean)
                        </label>
                        <div class="input-group">
                          <input
                            :id="'variation-' + variation.id + '-sku'"
                            v-model="variation.ean"
                            type="text"
                            class="form-control"
                            placeholder="Artikelnummer"
                          />
                        </div>
                      </div>
                      <div class="mt-3">
                        <label>BTW</label>
                        <div class="mt-2">
                          <select
                            v-model="variation.tax_id"
                            class="form-select box mt-3 sm:mt-0"
                          >
                            <option disabled hidden :value="undefined">
                              --- Kies een BTW-klasse ---
                            </option>
                            <option
                              v-for="tax in taxes"
                              :key="tax.id"
                              :value="tax.id"
                            >
                              {{ tax.name }} ({{ tax.rate }})
                            </option>
                          </select>
                        </div>
                      </div>
                      <div class="mt-3">
                        <label
                          :for="'variation-' + variation.id + '-advised-price'"
                          class="form-label"
                          >Adviesprijs</label
                        >
                        <div class="input-group">
                          <input
                            :id="'variation-' + variation.id + '-advised-price'"
                            v-model="variation.advised_price"
                            type="number"
                            class="form-control"
                            placeholder="Adviesprijs"
                          />
                        </div>
                      </div>
                      <div class="mt-3">
                        <label
                          :for="'variation-' + variation.id + '-price'"
                          class="form-label"
                          >Prijs</label
                        >
                        <div class="input-group">
                          <input
                            :id="'variation-' + variation.id + '-price'"
                            v-model="variation.price"
                            type="number"
                            class="form-control"
                            placeholder="Prijs"
                          />
                        </div>
                      </div>
                      <div class="mt-3">
                        <label
                          :for="'variation-' + variation.id + '-stock-level'"
                          class="form-label"
                          >Voorraad</label
                        >
                        <div class="input-group">
                          <input
                            :id="'variation-' + variation.id + '-stock-level'"
                            v-model="variation.stock_level"
                            type="number"
                            class="form-control"
                            placeholder="Voorraad"
                          />
                        </div>
                      </div>
                      <div class="mt-3">
                        <label>Actief</label>
                        <div class="mt-2">
                          <input
                            v-model="variation.active"
                            type="checkbox"
                            class="form-check-switch"
                          />
                        </div>
                      </div>
                      <div class="mt-3">
                        <label>Presale product</label>
                        <div class="mt-2">
                          <input
                            v-model="variation.presale"
                            type="checkbox"
                            class="form-check-switch"
                          />
                        </div>
                      </div>
                      <div class="mt-3">
                        <label>Description</label>
                        <div class="mt-2">
                          <CKEditor
                            v-model="variation.description"
                            :editor="classicEditor"
                            :config="simpleEditorConfig"
                          />
                        </div>
                      </div>
                      <div class="text-right mt-5">
                        <button
                          type="button"
                          class="btn btn-danger w-24 mr-1"
                          @click="maybeDeleteProductVariation(variation.id)"
                        >
                          Delete
                        </button>
                        <button
                          type="button"
                          class="btn btn-outline-secondary w-24 mr-1"
                        >
                          Cancel
                        </button>
                        <button
                          type="button"
                          class="btn btn-primary w-24"
                          @click="saveProductVariation(key)"
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import API from "@/libs/API/index";
import ImagePopup from "@/components/ImageInput/ImageInput";
import store from "@/store";
import AlertBar from "@/components/Alerts/AlertBar";
import Loader from "@/components/Loader/Loader";
import ImageSelector from "@/components/ImageSelector/ImageSelector";

export default {
  name: "ProductForm",
  components: { ImageSelector, Loader, AlertBar, ImagePopup },

  data() {
    return {
      classicEditor: ClassicEditor,
      simpleEditorConfig: {
        plugins: [
          ParagraphPlugin,
          BoldPlugin,
          UnderlinePlugin,
          ItalicPlugin,
          LinkPlugin
        ],
        toolbar: {
          items: ["bold", "italic", "underline", "link"]
        }
      },
      editorData: ref("<p>Content of the editor.</p>"),
      product: {
        id: null,
        name: null,
        categories: [],
        image_id: null,
        images: [],
        description: "",
        active: false,
        stock_level: null,
        slug: null,
        variations: null
      },
      alertBarKey: 0,
      loading: true
    };
  },
  computed: {
    taxes() {
      return store.getters["models/taxes/taxes"];
    },
    categories() {
      return store.getters["models/categories/categories"];
    },
    create() {
      return this.product.id === null;
    }
  },
  created() {
    store.dispatch("models/taxes/initialize");
    store.dispatch("models/categories/initialize");
    this.refreshProduct();
  },
  methods: {
    checkCanAddImage() {
      if (this.create) {
        store.dispatch(
          "messages/addErrorMessage",
          "Je moet het hoofdproduct eerst opslaan voordat je een afbeelding toe kan voegen."
        );
        this.alertBarKey++;
      }
    },
    addVariation() {
      if (this.create) {
        store.dispatch(
          "messages/addErrorMessage",
          "Je moet het hoofdproduct eerst opslaan voordat je een variatie toe kan voegen."
        );
        this.alertBarKey++;
        return;
      }

      if (this.product.variations === null) {
        this.product.variations = [];
      }

      this.product.variations.unshift({
        id: undefined,
        active: false,
        images: [],
        presale: false,
        advised_price: undefined,
        description: undefined,
        image: undefined,
        name: undefined,
        price: undefined,
        sku: undefined,
        stock_level: undefined,
        new: false
      });
    },
    async refreshProduct() {
      if (this.$route.name.endsWith("create")) {
        this.loading = false;
        return;
      }
      this.loading = true;
      const response = await API.product.get(this.$route.params.slug);
      const product = response.data.product;
      const variations = [];

      for (const variation of product.products) {
        variations.push({
          id: variation.id,
          tax_id: variation.tax_id,
          active: variation.active === 1,
          presale: variation.type === "presale",
          advised_price: variation.advised_price.toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          }),
          description: variation.description,
          image_id: variation.image ? variation.image.id : null,
          images: variation.images,
          name: variation.name,
          price: variation.price.toLocaleString("en-US", {
            maximumFractionDigits: 2,
            minimumFractionDigits: 2
          }),
          sku: variation.sku,
          ean: variation.ean,
          stock_level: variation.stock_level,
          new: false
        });
      }

      this.product = {
        id: product.id,
        name: product.name,
        categories: this.parseCategories(product.categories),
        image_id: product.image ? product.image.id : null,
        images: product.images,
        description: product.description,
        active: product.active === 1,
        stock_level: product.stock_level,
        slug: product.slug,
        variations: variations
      };
      this.loading = false;
    },
    parseCategories(categories) {
      const result = [];

      for (const category of categories) {
        result.push(category.id);
      }

      return result;
    },
    async updateProduct() {
      this.loading = true;
      const response = await API.product.update(this.product.id, {
        name: this.product.name,
        description: this.product.description,
        slug: this.product.slug,
        stock_level: this.product.stock_level,
        active: this.product.active,
        image_id: this.product.image_id,
        categories: this.product.categories
      });

      await this.handleResponse(response);
      this.loading = false;

      if (this.$route.params.slug !== this.product.slug) {
        if (response.status === 200) {
          await this.$router.push({
            name: "product.edit",
            params: { slug: this.product.slug }
          });
        }
      }
    },
    async createProduct() {
      this.loading = true;

      const response = await API.product.create({
        name: this.product.name,
        description: this.product.description,
        slug: this.product.slug,
        stock_level: this.product.stock_level,
        active: this.product.active,
        image_id: this.product.image_id,
        categories: this.product.categories
      });

      if (response.status === 200) {
        this.product.id = response.data.id;
      }

      await this.handleResponse(response);

      this.loading = false;

      if (response.status === 200) {
        await this.$router.push({
          name: "product.edit",
          params: { slug: this.product.slug }
        });
      }
    },
    saveProduct() {
      if (this.create) {
        this.createProduct();
      } else {
        this.updateProduct();
      }
    },
    async updateProductVariation(variation) {
      return await API.product.updateVariation(
        this.product.id,
        variation.id,
        variation
      );
    },
    async saveProductVariation(key) {
      this.loading = true;
      const variation = this.product.variations[key];
      let response;

      variation.advised_price = parseFloat(
        variation.advised_price
      ).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      });
      variation.price = parseFloat(variation.price).toLocaleString("en-US", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2
      });

      if (typeof variation.id === "undefined") {
        response = await this.createProductVariation(variation);
      } else {
        response = await this.updateProductVariation(variation);
      }

      await this.handleResponse(response);
      this.loading = false;
    },
    async createProductVariation(variation) {
      return await API.product.createVariation(this.product.id, variation);
    },
    getVariationById(id) {
      const matches = this.product.variations.filter(el => el.id === id);
      if (typeof matches[0] === "undefined") {
        return null;
      }

      return matches[0];
    },
    flatten(items) {
      const flat = [];

      if (typeof items === "object") {
        items = Object.values(items);
      }

      items.forEach(item => {
        if (Array.isArray(item)) {
          flat.push(...this.flatten(item));
        } else {
          flat.push(item);
        }
      });

      return flat;
    },
    async maybeDeleteProductVariation(id) {
      if (
        !confirm(
          "Weet je het zeker dat je de productvariatie wilt verwijderen?"
        )
      ) {
        return;
      }

      const response = await API.product.deleteVariation(this.product.id, id);

      if (response.status >= 200 && response.status < 300) {
        await store.dispatch(
          "messages/addSuccessMessage",
          "Variatie verwijderd!"
        );
        await this.refreshProduct();
      } else {
        await store.dispatch(
          "messages/addErrorMessage",
          "Er ging iets mis! Probeer het opnieuw!"
        );
      }
      this.alertBarKey++;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async handleResponse(response) {
      if (response.status >= 200 && response.status < 300) {
        await store.dispatch(
          "messages/addSuccessMessage",
          "Product opgeslagen!"
        );
      } else {
        let message = "Er ging iets mis!";

        if (response.status === 422) {
          message += " " + this.flatten(response.data.errors).join(" ");
        } else {
          message += " Probeer het opnieuw.";
        }

        await store.dispatch("messages/addErrorMessage", message);
      }

      this.alertBarKey++;
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
};
</script>
