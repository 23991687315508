<template>
  <div>
    <Loader v-if="loading" />
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Presale
      </h2>
    </div>
    <div
      class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
    >
      <router-link
        :to="{ name: 'presale.create' }"
        class="btn btn-primary shadow-md mr-2"
      >
        Presale toevoegen
      </router-link>
    </div>
    <AlertBar :key="alertBarKey" />
    <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
      <table class="table table-report -mt-2">
        <thead>
          <tr>
            <th class="whitespace-nowrap">Presale ID</th>
            <th class="whitespace-nowrap">Datum</th>
            <th class="text-center whitespace-nowrap">Acties</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="presaleItem of presaleItems"
            :key="presaleItem.id"
            class="intro-x"
          >
            <td class="w-40">{{ presaleItem.id }}</td>
            <td class="w-40">
              {{ presaleItem.date }}
            </td>
            <td class="table-report__action w-56">
              <div class="flex justify-center items-center">
                <router-link
                  class="btn btn-primary"
                  :to="{ name: 'presale.show', params: { id: presaleItem.id } }"
                >
                  <EyeIcon class="w-4 h-4 mr-1" />
                  Bekijken
                </router-link>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader/Loader";
import AlertBar from "@/components/Alerts/AlertBar";
import store from "@/store";

export default {
  name: "List",
  components: { AlertBar, Loader },
  data() {
    return {
      loading: true,
      alertBarKey: 0
    };
  },
  computed: {
    presaleItems() {
      return store.getters["models/presale/presale"];
    }
  },
  async created() {
    await store.dispatch("models/presale/initialize");
    this.loading = false;
  }
};
</script>

<style scoped></style>
