const formatter = new Intl.NumberFormat("nl-NL", {
  style: "currency",
  currency: "EUR"
});

const prices = {
  format(price) {
    if (price === null || isNaN(price)) {
      return price;
    }

    return formatter.format(price);
  }
};

export default prices;
