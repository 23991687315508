import address from "@/libs/API/partials/address";
import auth from "@/libs/API/partials/auth";
import statistics from "@/libs/API/partials/statistics";
import order from "@/libs/API/partials/order";
import product from "@/libs/API/partials/product";
import customer from "@/libs/API/partials/customer";
import userGroup from "@/libs/API/partials/user-group";
import taxes from "@/libs/API/partials/taxes";
import categories from "@/libs/API/partials/categories";
import presale from "@/libs/API/partials/presale";
import discount from "@/libs/API/partials/discount";

export default {
  address,
  auth,
  statistics,
  order,
  product,
  customer,
  userGroup,
  taxes,
  categories,
  presale,
  discount
};
