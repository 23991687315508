import base from "@/libs/API/partials/base";

const address = {
  async store(data) {
    const url = base.getAdminUrl("address");
    return await base.post(url, data);
  },

  async update(id, data) {
    const url = base.getAdminUrl("address/" + id);
    data._method = "PUT";
    return await base.post(url, data);
  }
};

export default address;
