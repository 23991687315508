import store from "@/store";
import base from "./base";

const auth = {
  async login(username, password) {
    const url = base.getUrl("login");

    const response = await base.post(
      url,
      {
        email: username,
        password: password
      },
      {},
      false
    );

    if (response.status === 200 && response.data.success === true) {
      await store.dispatch("dieAdmin/setToken", response.data.token);
    }

    return response;
  }
};

export default auth;
