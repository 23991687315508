<template>
  <div class="loader">
    <LoadingIcon icon="tail-spin" class="loading-icon" />
  </div>
</template>

<script>
import LoadingIcon from "@/global-components/loading-icon/Main";

export default {
  name: "Loader",
  components: { LoadingIcon }
};
</script>

<style lang="scss">
.loader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(255, 255, 255, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}
</style>
