import axios from "axios";
import store from "@/store";
import config from "@/config";
import router from "@/router";
import contentDisposition from "content-disposition";
import fileDownload from "js-file-download";

const base = {
  getUrl(endpoint) {
    return config.API_LOCATION + "/" + endpoint;
  },
  getAdminUrl(endpoint) {
    return config.API_LOCATION + "/admin/" + endpoint;
  },
  getAuthenticationHeader() {
    return { Authorization: `Bearer ${store.getters["dieAdmin/token"]}` };
  },
  async get(url, config = {}, token = true) {
    config = mergeConfig(config, token);
    let result;

    try {
      result = await axios.get(url, config);
    } catch (e) {
      if (e.response.status === 401) {
        await unauthorized();
      }
      return e.response;
    }

    return result;
  },
  async post(url, data, config = {}, token = true) {
    config = mergeConfig(config, token);
    let result;
    try {
      result = await axios.post(url, data, config);
    } catch (e) {
      if (e.response.status === 401) {
        await unauthorized();
      }
      return e.response;
    }

    return result;
  },
  async delete(url, data, config = {}, token = true) {
    if (!(typeof data === "object" && data !== null)) {
      data = {};
    }
    data._method = "DELETE";
    return this.post(url, data, config, token);
  },
  async put(url, data, config = {}, token = true) {
    data._method = "PUT";
    return this.post(url, data, config, token);
  },
  async download(url, config = {}, token = true) {
    config.responseType = "blob";
    const response = await this.get(url, config, token);

    const filename = contentDisposition.parse(
      response.headers["content-disposition"]
    ).parameters.filename;

    fileDownload(response.data, filename);

    return response;
  }
};

async function unauthorized() {
  await store.dispatch("dieAdmin/deleteAuthToken");
  console.log(router);
  if (router.currentRoute.value.name !== "Login") {
    await router.push({ name: "login" });
  }
}

function mergeConfig(config, token = false) {
  if (token) {
    Object.assign(config, {
      headers: base.getAuthenticationHeader()
    });
  }

  return config;
}

export default base;
