import base from "@/libs/API/partials/base";

const categories = {
  async getAll() {
    const url = base.getAdminUrl("category");
    return await base.get(url);
  }
};

export default categories;
