<template>
  <div>
    <div class="intro-y grid grid-cols-12 flex items-center mt-8">
      <div v-if="!edit" class="lg:col-span-3" />
      <h2 class="text-lg font-medium mr-auto col-span-12 lg:col-span-6">
        Klant {{ edit ? "aanpassen" : "toevoegen" }}
      </h2>
      <div class="lg:col-span-3" />
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <AlertBar :key="alertBarKey" class="col-span-12" />
      <div v-if="!edit" class="lg:col-span-3" />
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <form
          v-if="!loading"
          class="intro-y box p-5"
          @submit.prevent="submitForm"
        >
          <div>
            <label for="crud-form-1" class="form-label">Naam</label>
            <input
              id="crud-form-1"
              v-model="customer.name"
              type="text"
              class="form-control w-full"
              placeholder="Naam"
            />
          </div>
          <div class="mt-3">
            <label for="crud-form-3" class="form-label">Email</label>
            <div class="input-group">
              <input
                id="crud-form-3"
                v-model="customer.email"
                type="email"
                class="form-control"
                placeholder="Email"
              />
            </div>
          </div>
          <div class="mt-3">
            <label for="crud-form-3" class="form-label">Klantengroep</label>
            <div class="input-group">
              <select
                v-model="customer.group"
                class="form-select"
                aria-label="user-group"
              >
                <option
                  v-for="group of userGroups"
                  :key="group.id"
                  :value="group.id"
                >
                  {{ group.name }}
                </option>
              </select>
            </div>
          </div>
          <div class="mt-3">
            <label for="crud-form-3" class="form-label">Klantenkorting</label>
            <table class="table table--sm">
              <thead>
                <tr>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Naam
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Type
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Waarde
                  </th>
                  <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                    Acties
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr v-for="discount of customer.discounts" :key="discount.id">
                  <td class="border-b dark:border-dark-5">
                    {{ discount.name }}
                  </td>
                  <td class="border-b dark:border-dark-5">
                    {{ discount.type }}
                  </td>
                  <td class="border-b dark:border-dark-5">
                    {{ discount.amount }}
                  </td>
                  <td class="border-b dark:border-dark-5">
                    <button
                      class="flex items-center mr-3"
                      @click="deleteDiscount(discount.id)"
                    >
                      <Trash2Icon class="w-4 h-4 mr-1" />
                      Verwijderen
                    </button>
                  </td>
                </tr>
                <tr>
                  <td class="border-b dark:border-dark-5">
                    <input
                      id="new-discount-name"
                      v-model="newDiscount.name"
                      type="text"
                      class="form-control w-full"
                      placeholder="Naam"
                    />
                  </td>
                  <td class="border-b dark:border-dark-5">
                    <select v-model="newDiscount.type" class="form-select">
                      <option :value="undefined" disabled selected hidden>
                        --- Maak een keuze ---
                      </option>
                      <option value="relative">
                        Relatief
                      </option>
                      <option value="absolute">
                        Absoluut
                      </option>
                    </select>
                  </td>
                  <td class="border-b dark:border-dark-5">
                    <input
                      id="new-discount-value"
                      v-model="newDiscount.value"
                      type="number"
                      step="0.01"
                      min="0"
                      class="form-control w-full"
                      placeholder="Waarde"
                    />
                  </td>
                  <td class="border-b dark:border-dark-5">
                    <button
                      class="btn btn-primary flex items-center mr-3"
                      @click="saveNewDiscount"
                    >
                      <SaveIcon class="w-4 h-4 mr-1" />
                      Opslaan
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="text-right mt-5">
            <button type="button" class="btn btn-outline-secondary w-24 mr-1">
              Cancel
            </button>
            <button type="submit" class="btn btn-primary w-24">Opslaan</button>
          </div>
        </form>
        <!-- END: Form Layout -->
      </div>
      <div v-if="edit" class="col-span-12 lg:col-span-6 box p-5">
        <tabs v-model="selectedTab" class="tab-headers">
          <tab
            val="addresses"
            class="tab-header"
            :class="{ selected: selectedTab === 'addresses' }"
          >
            Adressen
          </tab>
          <tab
            val="orders"
            class="tab-header"
            :class="{ selected: selectedTab === 'orders' }"
          >
            Bestellingen
          </tab>
        </tabs>
        <tab-panels v-model="selectedTab" class="tab-panels">
          <tab-panel val="addresses">
            <button
              class="btn btn-primary shadow-md mb-3"
              type="button"
              @click="addAddress"
            >
              Adres toevoegen
            </button>
            <div
              v-if="selectedTab === 'addresses'"
              id="address-accordion"
              class="accordion accordion-boxed"
            >
              <div
                v-for="(address, id) of customer.addresses"
                :key="address.id"
                class="accordion-item"
              >
                <div
                  :id="'address-accordion-content-' + address.id"
                  class="accordion-header"
                >
                  <button
                    class="accordion-button"
                    type="button"
                    data-bs-toggle="collapse"
                    :data-bs-target="'address-accordion-collapse-' + address.id"
                    aria-expanded="true"
                    :aria-controls="'address-accordion-collapse-' + address.id"
                  >
                    <template
                      v-if="
                        address.address_line_1 ||
                          address.postal_code ||
                          address.city
                      "
                    >
                      {{ address.address_line_1 }},
                      <template v-if="address.postal_code">
                        {{ address.postal_code }},
                      </template>
                      {{ address.city }}
                    </template>
                    <template v-else>
                      Nieuw adres
                    </template>
                  </button>
                </div>
                <div
                  :id="'address-accordion-collapse-' + address.id"
                  class="accordion-collapse collapse show"
                  :aria-labelledby="'address-accordion-content-' + address.id"
                  data-bs-parent="#address-accordion"
                >
                  <form
                    class="intro-y box p-5"
                    @submit.prevent="saveAddress(id)"
                  >
                    <div>
                      <label
                        :for="'address-' + address.id + '-first-name'"
                        class="form-label"
                      >
                        Voornaam
                      </label>
                      <input
                        :id="'address-' + address.id + '-first-name'"
                        v-model="address.first_name"
                        type="text"
                        class="form-control w-full"
                        placeholder="Voornaam"
                      />
                    </div>
                    <div>
                      <label
                        :for="'address-' + address.id + '-middle-name'"
                        class="form-label"
                      >
                        Tussenvoegsel
                      </label>
                      <input
                        :id="'address-' + address.id + '-middle-name'"
                        v-model="address.middle_name"
                        type="text"
                        class="form-control w-full"
                        placeholder="Tussenvoegsel"
                      />
                    </div>
                    <div>
                      <label
                        :for="'address-' + address.id + '-last-name'"
                        class="form-label"
                      >
                        Achternaam
                      </label>
                      <input
                        :id="'address-' + address.id + '-last-name'"
                        v-model="address.last_name"
                        type="text"
                        class="form-control w-full"
                        placeholder="Achternaam"
                      />
                    </div>
                    <div>
                      <label
                        :for="'address-' + address.id + '-company'"
                        class="form-label"
                      >
                        Bedrijfsnaam
                      </label>
                      <input
                        :id="'address-' + address.id + '-company'"
                        v-model="address.company"
                        type="text"
                        class="form-control w-full"
                        placeholder="Bedrijfsnaam"
                      />
                    </div>
                    <div>
                      <label
                        :for="'address-' + address.id + '-phone'"
                        class="form-label"
                      >
                        Telefoonnummer
                      </label>
                      <input
                        :id="'address-' + address.id + '-phone'"
                        v-model="address.phone"
                        type="tel"
                        class="form-control w-full"
                        placeholder="Telefoonnummer"
                      />
                    </div>
                    <div>
                      <label
                        :for="'address-' + address.id + '-address-line-1'"
                        class="form-label"
                      >
                        Adres regel 1
                      </label>
                      <input
                        :id="'address-' + address.id + '-address-line-1'"
                        v-model="address.address_line_1"
                        type="text"
                        class="form-control w-full"
                        placeholder="Adres regel 1"
                      />
                    </div>
                    <div>
                      <label
                        :for="'address-' + address.id + '-address-line-2'"
                        class="form-label"
                      >
                        Adres regel 2
                      </label>
                      <input
                        :id="'address-' + address.id + '-address-line-2'"
                        v-model="address.address_line_2"
                        type="text"
                        class="form-control w-full"
                        placeholder="Adres regel 2"
                      />
                    </div>
                    <div>
                      <label
                        :for="'address-' + address.id + '-postal-code'"
                        class="form-label"
                      >
                        Postcode
                      </label>
                      <input
                        :id="'address-' + address.id + '-postal-code'"
                        v-model="address.postal_code"
                        type="text"
                        class="form-control w-full"
                        placeholder="Postcode"
                      />
                    </div>
                    <div>
                      <label
                        :for="'address-' + address.id + '-city'"
                        class="form-label"
                      >
                        Stad
                      </label>
                      <input
                        :id="'address-' + address.id + '-city'"
                        v-model="address.city"
                        type="text"
                        class="form-control w-full"
                        placeholder="Stad"
                      />
                    </div>
                    <div>
                      <label
                        :for="'address-' + address.id + '-country'"
                        class="form-label"
                      >
                        Land
                      </label>
                      <select v-model="address.country_id" class="form-select">
                        <option value="" disabled selected hidden>
                          --- Maak een keuze ---
                        </option>
                        <option
                          v-for="country of available_countries"
                          :key="country.id"
                          :value="country.id"
                        >
                          {{ country.name }}
                        </option>
                      </select>
                    </div>
                    <div class="text-right mt-5">
                      <button type="submit" class="btn btn-primary w-24">
                        Opslaan
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </tab-panel>
          <tab-panel val="orders">
            <div
              v-if="selectedTab === 'orders'"
              id="orders-accordion"
              class="accordion accordion-boxed"
            >
              <div class="overflow-x-auto">
                <table class="table table--sm">
                  <thead>
                    <tr>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        #
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Datum
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Status
                      </th>
                      <th
                        class="border-b-2 dark:border-dark-5 whitespace-nowrap"
                      >
                        Bekijken
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="order of customer.orders" :key="order.id">
                      <td class="border-b dark:border-dark-5">
                        {{ order.id }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ order.created }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        {{ order.status }}
                      </td>
                      <td class="border-b dark:border-dark-5">
                        <router-link
                          :to="{
                            name: 'order.show',
                            params: { id: order.id }
                          }"
                          class="flex items-center mr-3"
                        >
                          <EyeIcon class="w-4 h-4 mr-1" />
                          Bekijken
                        </router-link>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </tab-panel>
        </tab-panels>
      </div>
      <div v-if="!edit" class="lg:col-span-3" />
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import ParagraphPlugin from "@ckeditor/ckeditor5-paragraph/src/paragraph";
import BoldPlugin from "@ckeditor/ckeditor5-basic-styles/src/bold";
import UnderlinePlugin from "@ckeditor/ckeditor5-basic-styles/src/underline";
import ItalicPlugin from "@ckeditor/ckeditor5-basic-styles/src/italic";
import LinkPlugin from "@ckeditor/ckeditor5-link/src/link";
import ClassicEditor from "@ckeditor/ckeditor5-editor-classic/src/classiceditor";
import API from "@/libs/API/index";
import store from "@/store";
import AlertBar from "@/components/Alerts/AlertBar";
import moment from "moment";

export default {
  name: "CustomerForm",
  components: { AlertBar },
  data() {
    return {
      classicEditor: ClassicEditor,
      simpleEditorConfig: {
        plugins: [
          ParagraphPlugin,
          BoldPlugin,
          UnderlinePlugin,
          ItalicPlugin,
          LinkPlugin
        ],
        toolbar: {
          items: ["bold", "italic", "underline", "link"]
        }
      },
      editorData: ref("<p>Content of the editor.</p>"),
      customer: {
        name: undefined,
        email: undefined,
        addresses: [],
        orders: [],
        group: undefined,
        discounts: []
      },
      userGroups: [],
      loading: true,
      edit: this.$route.params.id !== undefined,
      selectedTab: "addresses",
      alertBarKey: 0,
      newDiscount: {
        name: undefined,
        type: undefined,
        value: undefined
      },
      available_countries: [
        {
          id: 1,
          name: "Nederland"
        }
      ]
    };
  },
  created() {
    this.refreshCustomerGroups();
    this.refreshCustomer();
  },
  methods: {
    async refreshCustomerGroups() {
      const response = await API.userGroup.getAll();
      const groups = [];
      for (const group of response.data) {
        groups.push({
          id: group.id,
          name: group.name
        });
      }

      this.userGroups = groups;
    },
    async refreshCustomer() {
      if (!this.edit) {
        this.loading = false;
        return;
      }

      const response = await API.customer.get(this.$route.params.id);
      const user = response.data.user;

      this.customer = {
        id: user.id,
        name: user.name,
        email: user.email,
        group: user.roles.length > 0 ? user.roles[0].id : undefined,
        addresses: this.processAddresses(user.addresses),
        orders: this.processOrders(user.orders),
        discounts: this.processDiscounts(user.user_discounts)
      };

      this.loading = false;
    },
    processDiscounts(userDiscounts) {
      const result = [];

      for (const userDiscount of userDiscounts) {
        result.push({
          id: userDiscount.id,
          name: userDiscount.name,
          type: userDiscount.type,
          amount: userDiscount.amount
        });
      }

      return result;
    },
    submitForm() {
      if (this.edit) {
        this.updateCustomer();
      } else {
        this.storeCustomer();
      }
    },
    async storeCustomer() {
      const customer = {
        name: this.customer.name,
        email: this.customer.email,
        group: this.customer.group
      };
      const response = await API.customer.store(customer);

      if (response.status >= 300 || response.status < 200) {
        await store.dispatch(
          "messages/addErrorMessage",
          "Er ging iets mis! Probeer het opnieuw."
        );
        this.alertBarKey++;
        return;
      }

      await store.dispatch("messages/addSuccessMessage", "Klant toegevoegd!");
      await this.$router.push({ name: "customer.index" });
    },
    updateCustomer() {},
    processAddresses(addresses) {
      const result = [];

      for (const address of addresses) {
        result.push({
          id: address.id,
          address_line_1: address.address_line_1,
          address_line_2: address.address_line_2,
          city: address.city,
          company: address.company,
          country_id: address.country_id,
          first_name: address.first_name,
          last_name: address.last_name,
          middle_name: address.middle_name,
          phone: address.phone,
          postal_code: address.postal_code,
          new: false
        });
      }

      return result;
    },
    processOrders(orders) {
      const result = [];

      for (const order of orders) {
        result.push({
          id: order.id,
          created: moment(order.created_at).format("DD-MM-YYYY"),
          status: order.order_status.name
        });
      }

      return result;
    },
    async saveAddress(id) {
      const address = this.customer.addresses[id];

      let response;

      if (address.new) {
        address.user_id = this.customer.id;
        response = await API.address.store(address);
      } else {
        response = await API.address.update(address.id, address);
      }

      const data = response.data;

      if (data.succeeded) {
        await store.dispatch(
          "messages/addSuccessMessage",
          "Het adres is opgeslagen!"
        );
        this.customer.addresses[id].new = false;
        this.customer.addresses[id].id = data.address.id;
      } else {
        await store.dispatch(
          "messages/addErrorMessage",
          "Het adres kon niet worden opgeslagen! Probeer het opnieuw."
        );
      }

      this.alertBarKey++;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    addAddress() {
      this.customer.addresses.unshift({
        id: undefined,
        address_line_1: undefined,
        address_line_2: undefined,
        city: undefined,
        company: undefined,
        country_id: undefined,
        first_name: undefined,
        last_name: undefined,
        middle_name: undefined,
        phone: undefined,
        postal_code: undefined,
        new: true
      });
    },
    async saveNewDiscount() {
      const discount = {
        name: this.newDiscount.name,
        type: this.newDiscount.type,
        amount: this.newDiscount.value
      };
      const response = await API.discount.save(this.$route.params.id, discount);

      if (response.status >= 200 && response.status < 300) {
        await store.dispatch(
          "messages/addSuccessMessage",
          "Korting succesvol toegevoegd!"
        );
        this.newDiscount.value = undefined;
        this.newDiscount.name = undefined;
        this.newDiscount.type = undefined;
        await this.refreshCustomer();
      } else {
        await store.dispatch(
          "messages/addErrorMessage",
          "Er ging iets verkeerd. Probeer het opnieuw."
        );
      }

      this.alertBarKey++;
      window.scrollTo({ top: 0, behavior: "smooth" });
    },
    async deleteDiscount(id) {
      const response = await API.discount.delete(this.$route.params.id, id);

      if (response.status >= 200 && response.status < 300) {
        await store.dispatch(
          "messages/addSuccessMessage",
          "Korting succesvol verwijderd!"
        );
        await this.refreshCustomer();
      } else {
        await store.dispatch(
          "messages/addErrorMessage",
          "Er ging iets verkeerd. Probeer het opnieuw."
        );
      }

      this.alertBarKey++;
      window.scrollTo({ top: 0, behavior: "smooth" });
    }
  }
};
</script>
