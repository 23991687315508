import base from "@/libs/API/partials/base";

const orders = {
  async getAll(page, perPage) {
    const url = base.getUrl("admin/order?page=" + page + "&perPage=" + perPage);
    return await base.get(url);
  },
  async get(id) {
    const url = base.getAdminUrl("order/" + id);
    return await base.get(url);
  },
  async getAllStatuses() {
    const url = base.getAdminUrl("orderStatus");
    return await base.get(url);
  },
  async addHistory(id, data) {
    const url = base.getAdminUrl("order/" + id + "/history");
    return await base.post(url, data);
  },
  async downloadPackageSlip(order_id) {
    const url = base.getAdminUrl("order/" + order_id + "/package-slip");
    return await base.download(url);
  },
  async downloadFirstInvoice(orderId) {
    const url = base.getAdminUrl("order/" + orderId + "/invoice");
    return await base.download(url);
  },
  async downloadInvoice(orderId, invoiceId) {
    const url = base.getAdminUrl("order/" + orderId + "/invoice/" + invoiceId);
    return await base.download(url);
  }
};

export default orders;
