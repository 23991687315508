import base from "@/libs/API/partials/base";

const taxes = {
  async getAll() {
    const url = base.getAdminUrl("tax");
    return await base.get(url);
  }
};

export default taxes;
