<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Klantengroepen</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <router-link
          :to="{ name: 'customer.create' }"
          class="btn btn-primary shadow-md mr-2"
        >
          Klantengroep toevoegen
        </router-link>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <AlertBar />
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Klantengroep ID</th>
              <th class="whitespace-nowrap">Naam</th>
              <th class="text-center whitespace-nowrap">Acties</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="customerGroup of customerGroups"
              :key="customerGroup.id"
              class="intro-x"
            >
              <td class="w-40">{{ customerGroup.id }}</td>
              <td class="w-40">
                {{ customerGroup.name }}
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link
                    :to="{
                      name: 'customer_group.edit',
                      params: { id: customerGroup.id }
                    }"
                    class="flex items-center mr-3"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Edit
                  </router-link>
                  <a
                    class="flex items-center text-theme-24"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Delete
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
    </div>
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-danger w-24">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
import API from "@/libs/API/index";
import AlertBar from "@/components/Alerts/AlertBar";

export default {
  name: "CustomerGroupsList",
  components: { AlertBar },
  data() {
    return {
      loading: false,
      customerGroups: [],
      paginate: {
        page: undefined,
        perPage: undefined,
        from: undefined,
        to: undefined,
        amountOfItems: 0,
        amountOfPages: 0
      }
    };
  },
  created() {
    this.refreshCustomerGroups();
  },
  methods: {
    parseCustomerGroupData(data) {
      const groups = [];

      for (const group of data) {
        groups.push({
          id: group.id,
          name: group.name
        });
      }

      return groups;
    },
    async refreshCustomerGroups() {
      this.loading = true;
      const response = await API.userGroup.getAll();
      const data = response.data;
      this.customerGroups = this.parseCustomerGroupData(data);
      this.paginate.amountOfItems = data.length;
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
