import { createRouter, createWebHistory } from "vue-router";
import Login from "@/views/Auth/Login";
import TopMenu from "@/layouts/top-menu/Main.vue";
import Dashboard from "@/views/Dashboard";
import OrderList from "@/views/Orders/List.vue";
import OrderForm from "@/views/Orders/Form.vue";
import ProductList from "@/views/Products/List.vue";
import ProductForm from "@/views/Products/Form.vue";
import CustomerList from "@/views/Customers/List.vue";
import CustomerForm from "@/views/Customers/Form.vue";
import CustomerGroupForm from "@/views/Groups/Form";
import CustomerGroupList from "@/views/Groups/List";
import store from "@/store";
import PresaleForm from "@/views/Presale/Form";
import PresaleList from "@/views/Presale/List";
import PresaleShow from "@/views/Presale/Show";

const routes = [
  {
    path: "/",
    component: TopMenu,
    children: [
      {
        path: "/",
        redirect: { name: "dashboard" }
      },
      {
        path: "dashboard",
        name: "dashboard",
        component: Dashboard
      },
      {
        path: "order",
        name: "order.index",
        component: OrderList
      },
      {
        path: "order/:id",
        name: "order.show",
        component: OrderForm
      },
      {
        path: "product",
        name: "product.index",
        component: ProductList
      },
      {
        path: "product/:slug",
        name: "product.edit",
        component: ProductForm
      },
      {
        path: "product/create",
        name: "product.create",
        component: ProductForm
      },
      {
        path: "customer",
        name: "customer.index",
        component: CustomerList
      },
      {
        path: "customer/:id",
        name: "customer.edit",
        component: CustomerForm
      },
      {
        path: "customer/create",
        name: "customer.create",
        component: CustomerForm
      },
      {
        path: "customer-group",
        name: "customer_group.index",
        component: CustomerGroupList
      },
      {
        path: "customer-group/:id",
        name: "customer_group.edit",
        component: CustomerGroupForm
      },
      {
        path: "presale/create",
        name: "presale.create",
        component: PresaleForm
      },
      {
        path: "presale",
        name: "presale.index",
        component: PresaleList
      },
      {
        path: "presale/:id",
        name: "presale.show",
        component: PresaleShow
      }
    ]
  },
  {
    path: "/login",
    component: Login,
    name: "login"
  }
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return savedPosition || { left: 0, top: 0 };
  }
});

router.beforeEach((to, from, next) => {
  const isAuthenticated = store.getters["dieAdmin/token"] !== null;
  if (to.name !== "login" && !isAuthenticated) {
    next({ name: "login" });
  } else if (to.name === "login" && isAuthenticated) {
    next({ name: "dashboard" });
  } else {
    next();
  }
});

export default router;
