import base from "@/libs/API/partials/base";

const statistics = {
  async getStatistics() {
    const url = base.getUrl("admin/dashboard/statistics");
    return await base.get(url);
  }
};

export default statistics;
