<template>
  <!-- BEGIN: Pagination -->
  <div
    class="intro-y col-span-12 flex flex-wrap sm:flex-row sm:flex-nowrap items-center"
  >
    <ul class="pagination">
      <li>
        <button
          type="button"
          class="pagination__link"
          :disabled="!canGoBackward"
          @click="onFastBackward"
        >
          <ChevronsLeftIcon class="w-4 h-4" />
        </button>
      </li>
      <li>
        <button
          type="button"
          class="pagination__link"
          :disabled="!canGoBackward"
          @click="onBackward"
        >
          <ChevronLeftIcon class="w-4 h-4" />
        </button>
      </li>
      <li v-if="!canGoForward && canGoToPage(page - 2)">
        <button
          type="button"
          class="pagination__link"
          @click="goToPage(page - 2)"
        >
          {{ page - 2 }}
        </button>
      </li>
      <li v-if="canGoToPage(page - 1)">
        <button
          type="button"
          class="pagination__link"
          @click="goToPage(page - 1)"
        >
          {{ page - 1 }}
        </button>
      </li>
      <li>
        <button type="button" class="pagination__link pagination__link--active">
          {{ page }}
        </button>
      </li>
      <li v-if="canGoToPage(page + 1)">
        <button
          type="button"
          class="pagination__link"
          @click="goToPage(page + 1)"
        >
          {{ page + 1 }}
        </button>
      </li>
      <li v-if="!canGoBackward && canGoToPage(page + 2)">
        <button
          type="button"
          class="pagination__link"
          @click="goToPage(page + 2)"
        >
          {{ page + 2 }}
        </button>
      </li>
      <li>
        <button
          type="button"
          class="pagination__link"
          :disabled="!canGoForward"
          @click="onForward"
        >
          <ChevronRightIcon class="w-4 h-4" />
        </button>
      </li>
      <li>
        <button
          type="button"
          class="pagination__link"
          :disabled="!canGoForward"
          @click="onFastForward"
        >
          <ChevronsRightIcon class="w-4 h-4" />
        </button>
      </li>
    </ul>
    <select class="w-20 form-select box mt-3 sm:mt-0">
      <option v-for="amount in amountOfItems" :key="amount">
        {{ amount }}
      </option>
    </select>
  </div>
  <!-- END: Pagination -->
</template>

<script>
export default {
  name: "Pagination",
  props: {
    page: {
      required: true,
      type: Number
    },
    maxPages: {
      required: true,
      type: Number
    },
    amountOfItems: {
      required: false,
      type: Array,
      default() {
        return [10, 25, 35, 50];
      }
    }
  },
  computed: {
    canGoBackward() {
      return this.page > 1;
    },
    canGoForward() {
      return this.page < this.maxPages;
    }
  },
  methods: {
    onForward() {
      this.goToPage(Math.min(this.page + 1, this.maxPages));
    },
    onBackward() {
      this.goToPage(Math.max(this.page - 1, 0));
    },
    onFastForward() {
      this.goToPage(this.maxPages);
    },
    onFastBackward() {
      this.goToPage(0);
    },
    goToPage(page) {
      this.$emit("goToPage", page);
    },
    canGoToPage(page) {
      return page >= 1 && page <= this.maxPages;
    }
  }
};
</script>
