const state = () => {
  return {
    menu: [
      {
        icon: "HomeIcon",
        pageName: "dashboard",
        title: "Dashboard"
      },
      {
        icon: "PackageIcon",
        pageName: "order.index",
        title: "Bestellingen"
      },
      {
        icon: "ShoppingCartIcon",
        pageName: "product.index",
        title: "Producten"
      },
      {
        icon: "UsersIcon",
        pageName: "customer.index",
        title: "Klanten",
        subMenu: [
          {
            icon: "UsersIcon",
            pageName: "customer.index",
            title: "Klanten"
          },
          {
            icon: "UsersIcon",
            pageName: "customer_group.index",
            title: "Klantengroepen"
          }
        ]
      },
      {
        icon: "TagIcon",
        title: "Presale",
        pageName: "presale.index"
      },
      {
        icon: "SettingsIcon",
        title: "Instellingen",
        subMenu: [
          {
            title: "Verzendmethodes"
          },
          {
            title: "Betaalmethodes"
          }
        ]
      }
    ]
  };
};

// getters
const getters = {
  menu: state => state.menu
};

// actions
const actions = {};

// mutations
const mutations = {};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
