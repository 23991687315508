import API from "@/libs/API/index";

const state = () => {
  return {
    initialized: false,
    categories: []
  };
};

// getters
const getters = {
  categories: state => state.categories
};

// actions
const actions = {
  async initialize({ commit }) {
    commit("SET_INITIALIZED");
    await actions.refresh({ commit });
  },
  async refresh({ commit }) {
    const response = await API.categories.getAll();
    commit("SET_CATEGORIES", { categories: response.data });
  }
};

// mutations
const mutations = {
  ["SET_INITIALIZED"](state) {
    state.initialized = true;
  },
  ["SET_CATEGORIES"](state, { categories }) {
    state.categories = categories;
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
