import base from "@/libs/API/partials/base";

const customer = {
  async getAll(page, perPage, search = undefined) {
    const rawParams = {
      page: page,
      perPage: perPage
    };

    if (search !== undefined && search !== "") {
      rawParams.search = search;
    }

    const params = new URLSearchParams(rawParams).toString();

    const url = base.getAdminUrl("customer?" + params);
    return await base.get(url);
  },

  async get(id) {
    const url = base.getAdminUrl("customer/" + id);
    return await base.get(url);
  },

  async store(data) {
    const url = base.getAdminUrl("customer");
    return await base.post(url, data);
  },

  async update(data) {
    data._method = "PUT";
    return await this.store(data);
  },

  async getAuthTokenLink(id) {
    const url = base.getAdminUrl("customer/" + id + "/login-as");
    return await base.post(url);
  }
};

export default customer;
