<template>
  <div class="image-selector">
    <span v-if="possible.length === 0">
      Er zijn nog geen afbeeldingen geuploaded
    </span>
    <img
      v-for="(image, key) of possible"
      :key="image.url"
      :src="image.url + '?v=1'"
      :alt="image.title"
      :class="{ chosen: isChosen(image.id) }"
      crossorigin="anonymous"
      @click="choose(key)"
    />
  </div>
</template>

<script>
export default {
  name: "ImageSelector",
  props: {
    images: {
      required: true,
      type: Array
    },
    value: {
      type: Number,
      default() {
        return 0;
      }
    }
  },
  data() {
    return {
      possible: this.images,
      selected: this.value
    };
  },
  methods: {
    choose(id) {
      const val = this.possible[id].id;
      this.selected = val;
      this.$emit("update:modelValue", val);
    },
    isChosen(id) {
      return this.selected === id;
    }
  }
};
</script>

<style scoped>
.image-selector {
  display: flex;
}

.image-selector span {
  color: rgba(0, 0, 0, 0.3);
}

.image-selector img {
  max-width: 100px;
  cursor: pointer;
  margin-right: 5px;
  border: 1px solid lightgray;
}

.image-selector img:last-of-type {
  margin-right: 0;
}

.image-selector img.chosen {
  border-color: black;
}
</style>
