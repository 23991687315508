<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Producten</h2>
    <Loader v-if="loading" />
    <AlertBar :key="alertBarKey" />
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <router-link
          :to="{ name: 'product.create' }"
          class="btn btn-primary shadow-md mr-2"
        >
          Product toevoegen
        </router-link>
        <div class="hidden md:block mx-auto text-gray-600">
          {{ paginate.from }} - {{ paginate.to }} van
          {{ paginate.amountOfProducts }} producten
        </div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <form
            class="w-56 relative text-gray-700 dark:text-gray-300"
            @submit.prevent="refreshProducts"
          >
            <input
              v-model="search"
              type="text"
              class="form-control w-56 box pr-10 placeholder-theme-8"
              placeholder="Search..."
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
              @click="refreshProducts"
            />
          </form>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Product ID</th>
              <th class="whitespace-nowrap">Afbeelding</th>
              <th class="text-center whitespace-nowrap">Naam</th>
              <th class="text-center whitespace-nowrap">Acties</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="product of products" :key="product.id" class="intro-x">
              <td class="w-40">{{ product.id }}</td>
              <td class="w-40">
                <img
                  v-if="product.image"
                  :src="product.image"
                  :alt="product.name"
                />
              </td>
              <td>{{ product.name }}</td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link
                    :to="{
                      name: 'product.edit',
                      params: { slug: product.slug }
                    }"
                    class="flex items-center mr-3"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Edit
                  </router-link>
                  <a
                    class="flex items-center text-theme-24"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                    @click="prepareDelete(product.id)"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Delete
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <Pagination
        v-if="!loading"
        :page="paginate.page"
        :max-pages="paginate.amountOfPages"
        @goToPage="onGoToPage"
      />
    </div>
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
                @click="cancelDeletion"
              >
                Cancel
              </button>
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-danger w-24"
                @click="doDeletion"
              >
                Delete
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/Pagination";
import store from "@/store";
import API from "@/libs/API/index";
import AlertBar from "@/components/Alerts/AlertBar";
import Loader from "@/components/Loader/Loader";

export default {
  name: "ProductList",
  components: { Loader, AlertBar, Pagination },
  data() {
    return {
      loading: false,
      products: [],
      paginate: {
        page: store.getters["dieAdmin/product/page"],
        perPage: store.getters["dieAdmin/product/perPage"],
        from: undefined,
        to: undefined,
        amountOfProducts: 0,
        amountOfPages: 0
      },
      search: undefined,
      preparedDelete: undefined,
      alertBarKey: 0
    };
  },
  watch: {
    "paginate.page"(newVal) {
      this.refreshProducts();
      store.dispatch("dieAdmin/setOrderPage", newVal);
    }
  },
  created() {
    this.refreshProducts();
  },
  methods: {
    prepareDelete(id) {
      this.preparedDelete = id;
    },
    cancelDeletion() {
      this.preparedDelete = undefined;
    },
    async doDeletion() {
      this.loading = true;
      const response = await API.product.delete(this.preparedDelete);

      if (response.status === 200) {
        await store.dispatch(
          "messages/addSuccessMessage",
          "Product succesvol verwijderd!"
        );
        await this.refreshProducts();
      } else {
        await store.dispatch(
          "messages/addErrorMessage",
          "Product niet verwijderd! Probeer het opnieuw."
        );
      }
      this.preparedDelete = undefined;
      this.alertBarKey++;
    },
    onGoToPage(page) {
      this.paginate.page = page;
    },
    parseProductData(data) {
      const products = [];

      for (const product of data) {
        products.push({
          id: product.id,
          name: product.name,
          image: product.image ? product.image.url : null,
          slug: product.slug
        });
      }

      return products;
    },
    async refreshProducts() {
      this.loading = true;
      const response = await API.product.getAll(
        this.paginate.page,
        this.paginate.perPage,
        this.search
      );
      const data = response.data;
      this.products = this.parseProductData(data.data);
      this.paginate.amountOfProducts = data.total;
      this.paginate.amountOfPages = data.last_page;
      this.paginate.from = data.from;
      this.paginate.to = data.to;
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
