<template>
  <div>
    <Loader v-if="loading" />
    <div class="intro-y flex items-center mt-8">
      <h2 class="text-lg font-medium mr-auto">
        Presale resultaat bekijken
      </h2>
    </div>
    <AlertBar :key="alertBarKey" />
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div class="intro-y col-span-12">
        <div class="intro-y box p-5">
          <!-- BEGIN: Form Layout -->
          <p v-if="presaleItem.orders.length === 0">
            Er zijn geen orders door deze presale geupdated
          </p>
          <table v-else class="table table--sm">
            <thead>
              <tr>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  Bestelling
                </th>
                <th class="border-b-2 dark:border-dark-5 whitespace-nowrap">
                  Acties
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="order of presaleItem.orders" :key="order.id">
                <td>{{ order.id }}</td>
                <td>
                  <router-link
                    class="btn btn-primary"
                    :to="{ name: 'order.show', params: { id: order.id } }"
                  >
                    Naar bestelling
                  </router-link>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <!-- END: Form Layout -->
      </div>
    </div>
  </div>
</template>

<script>
import AlertBar from "@/components/Alerts/AlertBar";
import Loader from "@/components/Loader/Loader";
import API from "@/libs/API";

export default {
  name: "Show",
  components: { AlertBar, Loader },
  data() {
    return {
      loading: true,
      alertBarKey: 0,
      presaleItem: {
        orders: []
      }
    };
  },
  async created() {
    await this.refreshPresaleItem();
    this.loading = false;
  },
  methods: {
    async refreshPresaleItem() {
      const response = await API.presale.get(this.$route.params.id);
      this.presaleItem = response.data.presale_job;
    }
  }
};
</script>

<style scoped></style>
