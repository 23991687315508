<template>
  <div>
    <DarkModeSwitcher />
    <div class="container sm:px-10">
      <div class="block xl:grid grid-cols-2 gap-4">
        <!-- BEGIN: Login Info -->
        <div class="hidden xl:flex flex-col min-h-screen">
          <a href="" class="-intro-x flex items-center pt-5">
            <img
              :alt="'DieCommerce | ' + config.CUSTOMER_NAME"
              class="w-6"
              :src="require(`@/assets/images/logo.svg`)"
            />
            <span class="text-white text-lg ml-3">
              DieCommerce | {{ config.CUSTOMER_NAME }}
            </span>
          </a>
          <div class="my-auto">
            <img
              :alt="'DieCommerce | ' + config.CUSTOMER_NAME"
              class="-intro-x w-1/2 -mt-16"
              :src="require(`@/assets/images/illustration.svg`)"
            />
            <div
              class="-intro-x text-white font-medium text-4xl leading-tight mt-10"
            >
              A few more clicks to <br />
              sign in to your account.
            </div>
            <div
              class="-intro-x mt-5 text-lg text-white text-opacity-70 dark:text-gray-500"
            >
              Manage all your e-commerce accounts in one place
            </div>
          </div>
        </div>
        <!-- END: Login Info -->
        <!-- BEGIN: Login Form -->
        <form
          class="h-screen xl:h-auto flex py-5 xl:py-0 my-10 xl:my-0"
          @submit.prevent="login"
        >
          <div
            class="my-auto mx-auto xl:ml-20 bg-white dark:bg-dark-1 xl:bg-transparent px-5 sm:px-8 py-8 xl:p-0 rounded-md shadow-md xl:shadow-none w-full sm:w-3/4 lg:w-2/4 xl:w-auto"
          >
            <h2
              class="intro-x font-bold text-2xl xl:text-3xl text-center xl:text-left"
            >
              Inloggen
            </h2>
            <div
              v-if="error"
              class="alert alert-danger alert-dismissible show flex items-center mb-2"
              role="alert"
            >
              <AlertOctagonIcon class="w-6 h-6 mr-2" />
              {{ error }}
              <button
                type="button"
                class="btn-close"
                data-bs-dismiss="alert"
                aria-label="Close"
              >
                <XIcon class="w-4 h-4" />
              </button>
            </div>
            <div class="intro-x mt-8">
              <input
                v-model="username"
                type="text"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block"
                placeholder="Email"
              />
              <input
                v-model="password"
                type="password"
                class="intro-x login__input form-control py-3 px-4 border-gray-300 block mt-4"
                placeholder="Wachtwoord"
              />
            </div>
            <div
              class="intro-x flex text-gray-700 dark:text-gray-600 text-xs sm:text-sm mt-4"
            >
              <div class="flex items-center mr-auto">
                <input
                  id="remember-me"
                  type="checkbox"
                  class="form-check-input border mr-2"
                />
                <label class="cursor-pointer select-none" for="remember-me"
                  >Onthoud mij</label
                >
              </div>
              <a href="">Wachtwoord vergeten?</a>
            </div>
            <div class="intro-x mt-5 xl:mt-8 text-center xl:text-left">
              <button
                class="btn btn-primary py-3 px-4 w-full xl:w-32 xl:mr-3 align-top"
              >
                Inloggen
              </button>
            </div>
            <div
              class="intro-x mt-10 xl:mt-24 text-gray-700 dark:text-gray-600 text-center xl:text-left"
            >
              By signin up, you agree to our <br />
              <a class="text-theme-17 dark:text-gray-300" href=""
                >Terms and Conditions</a
              >
              &
              <a class="text-theme-17 dark:text-gray-300" href=""
                >Privacy Policy</a
              >
            </div>
          </div>
        </form>
        <!-- END: Login Form -->
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, onMounted } from "vue";
import DarkModeSwitcher from "@/components/dark-mode-switcher/Main.vue";
import config from "@/config";
import API from "@/libs/API/index";

export default defineComponent({
  components: {
    DarkModeSwitcher
  },
  setup() {
    onMounted(() => {
      cash("body")
        .removeClass("main")
        .removeClass("error-page")
        .addClass("login");
    });
  },
  data() {
    return {
      username: undefined,
      password: undefined,
      config: config,
      error: undefined
    };
  },
  methods: {
    async login() {
      this.error = undefined;

      const response = await API.auth.login(this.username, this.password);

      this.parseResponse(response.data);
    },
    parseResponse(response) {
      if (response.success) {
        this.$router.push("dashboard");
      } else {
        this.error = response.message;
      }
    }
  }
});
</script>
