<template>
  <div class="imageInput">
    <Dropzone
      ref="dropzoneSingleRef"
      ref-key="dropzoneSingleRef"
      :options="{
        url: uploadUrl,
        thumbnailWidth: 150,
        maxFilesize: maxFilesize,
        maxFiles: maxFiles,
        addRemoveLinks: true,
        removedfile: onFileDelete,
        success: onUploadSuccess,
        headers: dropzoneHeaders
      }"
      class="dropzone"
    >
      <div class="text-lg font-medium">
        Drop files here or click to upload.
      </div>
      <div class="text-gray-600">
        This is just a demo dropzone. Selected files are
        <span class="font-medium">not</span> actually uploaded.
      </div>
    </Dropzone>
  </div>
</template>

<script>
import base from "@/libs/API/partials/base";

export default {
  name: "ImagePopup",
  provide() {
    return {
      "bind[dropzoneSingleRef]": el => {
        this.dropzone = el;
      }
    };
  },
  props: {
    name: {
      required: true,
      type: String
    },
    uploadEndpoint: {
      required: true,
      type: String
    },
    deleteEndpoint: {
      required: true,
      type: String
    },
    maxFiles: {
      required: false,
      type: Number,
      default() {
        return null;
      }
    },
    maxFilesize: {
      required: false,
      type: Number,
      default() {
        return 1;
      }
    },
    currentImages: {
      required: false,
      type: Array,
      default() {
        return [];
      }
    }
  },
  data() {
    return {
      dropzone: undefined,
      images: this.currentImages
    };
  },
  computed: {
    uploadUrl() {
      return base.getAdminUrl(this.uploadEndpoint);
    },
    deleteUrl() {
      return base.getAdminUrl(this.deleteEndpoint);
    },
    dropzoneHeaders() {
      return base.getAuthenticationHeader();
    }
  },
  watch: {
    images(newVal) {
      this.$emit("update:modelValue", newVal);
    }
  },
  mounted() {
    for (const image of this.images) {
      this.addImage(image);
    }
  },
  methods: {
    addImage(image) {
      this.dropzone.dropzone.displayExistingFile(
        image,
        image.url + "?v=1",
        undefined,
        "Anonymous"
      );
    },
    onFileDelete(file) {
      this.deleteFromServer(file);
      const response = this.defaultFileDelete(file);
      this.$emit("imagesUpdated");
      return response;
    },
    onUploadSuccess(file) {
      const response = JSON.parse(file.xhr.response);
      file._id = response.id;
      this.defaultUploadSuccess(file);
    },
    deleteFromServer(file) {
      base.delete(this.deleteUrl, { id: file.id });
    },
    defaultFileDelete(file) {
      let _ref;
      return (_ref = file.previewElement) != null
        ? _ref.parentNode.removeChild(file.previewElement)
        : void 0;
    },
    defaultUploadSuccess(file) {
      if (file.previewElement) {
        return file.previewElement.classList.add("dz-success");
      }
    }
  }
};
</script>

<style scoped></style>
