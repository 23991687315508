import base from "@/libs/API/partials/base";

const product = {
  async getAll(page, perPage, search = undefined) {
    const rawParams = {
      page: page,
      perPage: perPage
    };

    if (search !== undefined && search !== "") {
      rawParams.search = search;
    }

    const params = new URLSearchParams(rawParams).toString();

    const url = base.getAdminUrl("product?" + params);
    return await base.get(url);
  },
  async get(slug) {
    const url = base.getAdminUrl("product/" + slug);
    return await base.get(url);
  },
  async update(id, data) {
    const url = base.getAdminUrl("product/" + id);
    return await base.put(url, data);
  },
  async create(data) {
    const url = base.getAdminUrl("product");
    return await base.post(url, data);
  },
  async updateVariation(parent_id, variation_id, data) {
    const url = base.getAdminUrl(
      "product/" + parent_id + "/variation/" + variation_id
    );
    return await base.put(url, data);
  },
  async createVariation(parent_id, data) {
    const url = base.getAdminUrl("product/" + parent_id + "/variation");
    return await base.post(url, data);
  },
  async deleteVariation(parentId, variationId) {
    const url = base.getAdminUrl(
      "product/" + parentId + "/variation/" + variationId
    );
    return await base.delete(url);
  },
  async delete(id) {
    const url = base.getAdminUrl("product/" + id);
    return await base.delete(url);
  }
};

export default product;
