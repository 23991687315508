<template>
  <div>
    <h2 class="intro-y text-lg font-medium mt-10">Bestellingen</h2>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <div
        class="intro-y col-span-12 flex flex-wrap sm:flex-nowrap items-center mt-2"
      >
        <div class="hidden md:block mx-auto text-gray-600">
          Showing 1 to 10 of {{ amountOfOrders }} entries
        </div>
        <div class="w-full sm:w-auto mt-3 sm:mt-0 sm:ml-auto md:ml-0">
          <div class="w-56 relative text-gray-700 dark:text-gray-300">
            <input
              type="text"
              class="form-control w-56 box pr-10 placeholder-theme-8"
              placeholder="Search..."
            />
            <SearchIcon
              class="w-4 h-4 absolute my-auto inset-y-0 mr-3 right-0"
            />
          </div>
        </div>
      </div>
      <!-- BEGIN: Data List -->
      <div class="intro-y col-span-12 overflow-auto lg:overflow-visible">
        <table v-if="orders" class="table table-report -mt-2">
          <thead>
            <tr>
              <th class="whitespace-nowrap">Bestelling ID</th>
              <th class="whitespace-nowrap">Naam</th>
              <th class="text-center whitespace-nowrap">Datum</th>
              <th class="text-center whitespace-nowrap">Waarde</th>
              <th class="text-center whitespace-nowrap">Status</th>
              <th class="text-center whitespace-nowrap">Acties</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="order in orders" :key="order.id" class="intro-x">
              <td class="w-40">{{ order.id }}</td>
              <td class="w-40">{{ order.full_name }}</td>
              <td class="w-40 text-center">{{ order.date }}</td>
              <td class="w-40 text-center">{{ order.value }}</td>
              <td class="w-40">
                <div class="flex items-center justify-center">
                  <CheckSquareIcon class="w-4 h-4 mr-2" />
                  {{ order.status }}
                </div>
              </td>
              <td class="table-report__action w-56">
                <div class="flex justify-center items-center">
                  <router-link
                    class="flex items-center mr-3"
                    :to="{ name: 'order.show', params: { id: order.id } }"
                  >
                    <CheckSquareIcon class="w-4 h-4 mr-1" />
                    Edit
                  </router-link>
                  <a
                    class="flex items-center text-theme-24"
                    href="javascript:;"
                    data-toggle="modal"
                    data-target="#delete-confirmation-modal"
                  >
                    <Trash2Icon class="w-4 h-4 mr-1" /> Delete
                  </a>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <!-- END: Data List -->
      <Pagination
        v-if="!loading"
        :page="page"
        :max-pages="amountOfPages"
        @goToPage="onGoToPage"
      />
    </div>
    <!-- BEGIN: Delete Confirmation Modal -->
    <div
      id="delete-confirmation-modal"
      class="modal"
      tabindex="-1"
      aria-hidden="true"
    >
      <div class="modal-dialog">
        <div class="modal-content">
          <div class="modal-body p-0">
            <div class="p-5 text-center">
              <XCircleIcon class="w-16 h-16 text-theme-24 mx-auto mt-3" />
              <div class="text-3xl mt-5">Are you sure?</div>
              <div class="text-gray-600 mt-2">
                Do you really want to delete these records? <br />This process
                cannot be undone.
              </div>
            </div>
            <div class="px-5 pb-8 text-center">
              <button
                type="button"
                data-dismiss="modal"
                class="btn btn-outline-secondary w-24 mr-1"
              >
                Cancel
              </button>
              <button type="button" class="btn btn-danger w-24">Delete</button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- END: Delete Confirmation Modal -->
  </div>
</template>

<script>
import Pagination from "@/components/Pagination/Pagination";
import store from "@/store";
import API from "@/libs/API/index";
import localization from "../../libs/Localization";

export default {
  name: "List",
  components: { Pagination },
  data() {
    return {
      page: store.getters["dieAdmin/order/page"],
      perPage: store.getters["dieAdmin/order/perPage"],
      loading: false,
      orders: undefined,
      amountOfOrders: 0,
      amountOfPages: 0
    };
  },
  watch: {
    page(newVal) {
      this.updateOrders();
      store.dispatch("dieAdmin/setOrderPage", newVal);
    }
  },
  mounted() {
    this.updateOrders();
  },
  methods: {
    onGoToPage(page) {
      this.page = page;
    },
    parseOrderData(data) {
      const orders = [];

      for (const order of data) {
        if (!("payment_address" in order)) {
          continue;
        }

        orders.push({
          id: order.id,
          full_name:
            order.payment_address.first_name +
            " " +
            (order.payment_address.middle_name !== null
              ? " " + order.payment_address.middle_name
              : "") +
            order.payment_address.last_name,
          value: localization.prices.format(order.total),
          status: order.order_status.name,
          date: localization.datetime.parse(order.created_at)
        });
      }

      return orders;
    },
    async updateOrders() {
      this.loading = true;
      const response = await API.order.getAll(this.page, this.perPage);
      this.orders = this.parseOrderData(response.data.data);
      this.amountOfOrders = response.data.total;
      this.amountOfPages = response.data.last_page;
      this.loading = false;
    }
  }
};
</script>

<style scoped></style>
