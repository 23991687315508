<template>
  <div>
    <div class="intro-y grid grid-cols-12 flex items-center mt-8">
      <div v-if="!edit" class="lg:col-span-3" />
      <h2 class="text-lg font-medium mr-auto col-span-12 lg:col-span-6">
        Klantengroep {{ edit ? "aanpassen" : "toevoegen" }}
      </h2>
      <div class="lg:col-span-3" />
    </div>
    <div class="grid grid-cols-12 gap-6 mt-5">
      <AlertBar :key="alertBarKey" class="col-span-12" />
      <div v-if="!edit" class="lg:col-span-3" />
      <div class="intro-y col-span-12 lg:col-span-6">
        <!-- BEGIN: Form Layout -->
        <form
          v-if="!loading"
          class="intro-y box p-5"
          @submit.prevent="submitForm"
        >
          <div>
            <label for="crud-form-1" class="form-label">Naam</label>
            <input
              id="crud-form-1"
              v-model="customerGroup.name"
              type="text"
              class="form-control w-full"
              placeholder="Naam"
              disabled
            />
          </div>
        </form>
        <!-- END: Form Layout -->
      </div>
      <div v-if="edit" class="col-span-12 lg:col-span-6 box p-5">
        <h2 class="text-lg font-medium mr-auto pb-2">Rechten</h2>
        <form class="permissions" @submit.prevent="submitPermissions">
          <label
            v-for="permission of allPermissions"
            :key="permission.id"
            style="display: block"
          >
            <input
              v-model="customerGroup.permissions"
              class="form-check-switch"
              type="checkbox"
              :value="permission.id"
            />
            {{ permission.name }}
          </label>
          <button type="submit" class="btn btn-primary mt-3">Opslaan</button>
        </form>
      </div>
      <div v-if="!edit" class="lg:col-span-3" />
    </div>
  </div>
</template>

<script>
import API from "@/libs/API/index";
import store from "@/store";
import AlertBar from "@/components/Alerts/AlertBar";

export default {
  name: "CustomerGroupForm",
  components: { AlertBar },
  data() {
    return {
      customerGroup: {
        id: undefined,
        name: undefined,
        permissions: []
      },
      allPermissions: [],
      loading: true,
      edit: this.$route.params.id !== undefined,
      alertBarKey: 0
    };
  },
  created() {
    this.refreshCustomerGroup();
    this.refreshPermissions();
  },
  methods: {
    async refreshCustomerGroup() {
      if (!this.edit) {
        this.loading = false;
        return;
      }

      const response = await API.userGroup.get(this.$route.params.id);
      const customerGroup = response.data.customerGroup;

      this.customerGroup = {
        id: customerGroup.id,
        name: customerGroup.name,
        permissions: this.parsePermissions(customerGroup.permissions)
      };

      this.loading = false;
    },
    parsePermissions(permissions) {
      const result = [];

      for (const permission of permissions) {
        if (permission.name.startsWith("user_group.")) {
          result.push(permission.id);
        }
      }

      return result;
    },
    submitForm() {
      if (this.edit) {
        this.updateCustomer();
      } else {
        this.storeCustomer();
      }
    },
    async submitPermissions() {
      const response = await API.userGroup.setPermissions(
        this.customerGroup.id,
        this.customerGroup.permissions
      );

      console.log(response.data);
    },
    async storeCustomer() {
      const customer = {
        name: this.customer.name,
        email: this.customer.email,
        group: this.customer.group
      };
      const response = await API.customer.store(customer);

      if (response.status >= 300 || response.status < 200) {
        await store.dispatch(
          "messages/addErrorMessage",
          "Er ging iets mis! Probeer het opnieuw."
        );
        this.alertBarKey++;
        return;
      }

      await store.dispatch("messages/addSuccessMessage", "Klant toegevoegd!");
      await this.$router.push({ name: "customer.index" });
    },
    async refreshPermissions() {
      const response = await API.userGroup.getPermissions();

      const permissions = [];

      for (const permission of response.data.permissions) {
        permissions.push({
          id: permission.id,
          name: permission.name
        });
      }

      this.allPermissions = permissions;
    }
  }
};
</script>

<style lang="scss"></style>
