const state = () => {
  return {
    errorMessages: [],
    successMessages: []
  };
};

// getters
const getters = {
  successMessages: state => {
    return state.successMessages;
  },
  errorMessages: state => {
    return state.errorMessages;
  },
  hasMessages: state =>
    state.successMessages.length > 0 || state.errorMessages.length > 0
};

// actions
const actions = {
  addSuccessMessage({ commit }, message) {
    commit("ADD_SUCCESS_MESSAGE", { message });
  },
  addErrorMessage({ commit }, message) {
    commit("ADD_ERROR_MESSAGE", { message });
  },
  clearSuccessMessage({ commit }) {
    commit("CLEAR_SUCCESS_MESSAGES");
  },
  clearErrorMessage({ commit }) {
    commit("CLEAR_ERROR_MESSAGES");
  }
};

// mutations
const mutations = {
  ADD_SUCCESS_MESSAGE(state, { message }) {
    state.successMessages.push(message);
  },
  ADD_ERROR_MESSAGE(state, { message }) {
    state.errorMessages.push(message);
  },
  CLEAR_ERROR_MESSAGES(state) {
    state.errorMessages = [];
  },
  CLEAR_SUCCESS_MESSAGES(state) {
    state.successMessages = [];
  }
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
};
